import React, {useState, useEffect, useRef} from 'react';
import Colors from "../../assets/backgrounds/colors.webp";
import Bar from "../../assets/shape/arrow_bar.webp";
import IconDownload from "../../assets/shape/ic_download.webp";
import IconRent from "../../assets/shape/ic_rent.webp";
import IconBuy from "../../assets/shape/ic_buy.webp";
import IconTrain from "../../assets/shape/ic_train.webp";
import IconEarn from "../../assets/shape/ic_earn.webp";
import './HowToStart.css';

export default function HowToStart(props) {
    const [backgroundClass, setBackgroundClass] = useState("howtostart-background-initial");
    const [headerClass, setHeaderClass] = useState("howtostart-header-initial0");
    const [rectClass1, setRectClass1] = useState("howtostart-rect1-initial0");
    const [rectClass2, setRectClass2] = useState("howtostart-rect2-initial0");
    const [rectClass3, setRectClass3] = useState("howtostart-rect3-initial0");
    const [rectClass4, setRectClass4] = useState("howtostart-rect4-initial0");
    const [textClass1, setTextClass1] = useState("howtostart-text1-initial0");
    const [textClass2, setTextClass2] = useState("howtostart-text2-initial0");
    const [textClass3, setTextClass3] = useState("howtostart-text3-initial0");
    const [textClass4, setTextClass4] = useState("howtostart-text4-initial0");
    const [iconClass1, setIconClass1] = useState("howtostart-icon1-initial0");
    const [iconClass2, setIconClass2] = useState("howtostart-icon2-initial0");
    const [iconClass3, setIconClass3] = useState("howtostart-icon3-initial0");
    const [iconClass4, setIconClass4] = useState("howtostart-icon4-initial0");
    const [earnClass, setEarnClass] = useState("howtostart-earn-initial0");
    const [earnWrapperClass, setEarnWrapperClass] = useState("howtostart-earn-wrapper-initial0");
    const [iconsClass, setIconsClass] = useState("");
    const [textsClass, setTextsClass] = useState("");

    const [animationEnded, setAnimationEnded] = useState(false);
    const animationIntervalLength = 500;
    const timeoutsQueue = useRef([]);

    useEffect(() => {
      return () => {
        clearTimeouts();
      };
    },[]);

    props.biRef.howtostart = {
        playAnimation: playAnimation,
        playClosingAnimation: playClosingAnimation,
    }

    function clearTimeouts(){
        timeoutsQueue.current.forEach((item) => {
            clearTimeout(item);
        });
        timeoutsQueue.current = [];
    }

    function playAnimation() {
        console.log("starting howtostart");
        props.biRef.main.enableScrollToSports();
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass1("howtostart-rect1-initial1");
            setHeaderClass("howtostart-header-initial1");
        }, animationIntervalLength);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass1("howtostart-rect1-initial2");
            setTextClass1("howtostart-text1-initial1");
            setRectClass2("howtostart-rect2-initial1");
        }, animationIntervalLength*2);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setIconClass1("howtostart-icon1-initial1");
        }, animationIntervalLength*3);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass2("howtostart-rect2-initial2");
        }, animationIntervalLength*4);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass2("howtostart-rect2-initial3");
            setTextClass2("howtostart-text2-initial1");
            setRectClass3("howtostart-rect3-initial1");
        }, animationIntervalLength*5);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setIconClass2("howtostart-icon2-initial1");
        }, animationIntervalLength*6);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass3("howtostart-rect3-initial2");
        }, animationIntervalLength*7);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass3("howtostart-rect3-initial3");
            setTextClass3("howtostart-text3-initial1");
            setRectClass4("howtostart-rect4-initial1");
        }, animationIntervalLength*8);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setIconClass3("howtostart-icon3-initial1");
        }, animationIntervalLength*9);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass4("howtostart-rect4-initial2");
        }, animationIntervalLength*10);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass4("howtostart-rect4-initial3");
            setTextClass4("howtostart-text4-initial1");
        }, animationIntervalLength*11);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setIconClass4("howtostart-icon4-initial1");
        }, animationIntervalLength*12);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setIconClass1("howtostart-icon1-initial2");
            setIconClass2("howtostart-icon2-initial2");
            setIconClass3("howtostart-icon3-initial2");
            setIconClass4("howtostart-icon4-initial2");
            setTextClass1("howtostart-text1-initial2");
            setTextClass2("howtostart-text2-initial2");
            setTextClass3("howtostart-text3-initial2");
            setTextClass4("howtostart-text4-initial2");
            setRectClass1("howtostart-rect1-initial3b");
            setRectClass2("howtostart-rect2-initial3b");
            setRectClass3("howtostart-rect3-initial3b");
            setRectClass4("howtostart-rect4-initial3b");
        }, animationIntervalLength*13);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass1("howtostart-rect1-initial4");
            setRectClass2("howtostart-rect2-initial4");
            setRectClass3("howtostart-rect3-initial4");
            setRectClass4("howtostart-rect4-initial4");
        }, animationIntervalLength*14);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setBackgroundClass("");
            setHeaderClass("");
            setRectClass1("howtostart-rect1-initial5");
            setRectClass2("howtostart-rect2-initial5");
            setRectClass3("howtostart-rect3-initial5");
            setRectClass4("howtostart-rect4-initial5");
            setTextClass1("");
            setTextClass2("");
            setTextClass3("");
            setTextClass4("");
            setIconClass1("");
            setIconClass2("");
            setIconClass3("");
            setIconClass4("");
        }, animationIntervalLength*15);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setRectClass1("");
            setRectClass2("");
            setEarnClass("");
        }, animationIntervalLength*16);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setEarnWrapperClass("");
            setRectClass3("howtostart-rect3-initial6");
            setRectClass4("howtostart-rect4-initial6");
            setAnimationEnded(true);
        }, animationIntervalLength*17);

        setBackgroundClass("howtostart-background-initial");
        setHeaderClass("howtostart-header-initial0");
        setRectClass1("howtostart-rect1-initial0");
        setRectClass2("howtostart-rect2-initial0");
        setRectClass3("howtostart-rect3-initial0");
        setRectClass4("howtostart-rect4-initial0");
        setTextClass1("howtostart-text1-initial0");
        setTextClass2("howtostart-text2-initial0");
        setTextClass3("howtostart-text3-initial0");
        setTextClass4("howtostart-text4-initial0");
        setIconClass1("howtostart-icon1-initial0");
        setIconClass2("howtostart-icon2-initial0");
        setIconClass3("howtostart-icon3-initial0");
        setIconClass4("howtostart-icon4-initial0");
        setEarnClass("howtostart-earn-initial0");
        setEarnWrapperClass("howtostart-earn-wrapper-initial0");
    }

    function playClosingAnimation(){
        if(animationEnded){
            setIconsClass("howtostart-icons-end");
            setTextsClass("howtostart-texts-end");
            setHeaderClass("howtostart-header-end");
            setEarnWrapperClass("howtostart-earn-wrapper-end");
            setRectClass3("howtostart-rect3-end");
            setRectClass4("howtostart-rect4-end");
            timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
                setIconsClass("");
                setTextsClass("");
                setHeaderClass("");
                setEarnWrapperClass("");
                setRectClass3("howtostart-rect3-initial6");
                setRectClass4("howtostart-rect4-initial6");
            },1000);
        }
    }

    function skipToFinal(){
        setHeaderClass("");
        setTextClass1("");
        setTextClass2("");
        setTextClass3("");
        setTextClass4("");
        setIconClass1("");
        setIconClass2("");
        setIconClass3("");
        setIconClass4("");
        setEarnClass("");
        setEarnWrapperClass("");
        setRectClass1("");
        setRectClass2("");
        setRectClass3("howtostart-rect3-initial6");
        setRectClass4("howtostart-rect4-initial6");
        // setTimeout(()=>{
        //     playClosingAnimation();
        // }, 2000);
    }

    return (
        <div className="howtostart wrtmain-section-wrapper">
            <div className="howtostart-content wrtmain-section-content">
                <h2 className={`howtostart-header ${headerClass}`}>How to start?</h2>
                <div className={`howtostart-rect howtostart-rect1 ${rectClass1}`}></div>
                <div className={`howtostart-rect howtostart-rect2 ${rectClass2}`}></div>
                <div className={`howtostart-rect howtostart-rect3 ${rectClass3}`}></div>
                <div className={`howtostart-rect howtostart-rect4 ${rectClass4}`}></div>
                <div className="howtostart-icons">
                    <img alt="how-to-start-1" className={`howtostart-icon1 ${iconClass1}`}
                        src={IconDownload}/>
                    <img  alt="how-to-start-2" className={`howtostart-icon2 ${iconClass2}`}
                        src={IconRent}/>
                    <img  alt="how-to-start-3" className={`howtostart-icon3 ${iconClass3}`}
                        src={IconBuy}/>
                    <img  alt="how-to-start-4" className={`howtostart-icon4 ${iconClass4}`}
                        src={IconTrain}/>
                </div>
                <div className="howtostart-texts">
                    <p className={`howtostart-text1 ${textClass1}`}>
                        Download<br/> the application
                    </p>
                    <p className={`howtostart-text2 ${textClass2}`}>
                        Rent chosen NFT
                    </p>
                    <p className={`howtostart-text3 ${textClass3}`}>
                        Buy or lend your NFT
                    </p>
                    <p className={`howtostart-text4 ${textClass4}`}>
                        Train !
                    </p>
                </div>
                <div className={`howtostart-earn-wrapper ${earnWrapperClass}`}>
                    <img  alt="how-to-start-earn"  className={`howtostart-earn ${earnClass}`}
                        src={IconEarn}/>
                </div>
            </div>
            <img  alt="how-to-start"  className={`howtostart-background ${backgroundClass}`}
                src={ Colors }/>
        </div>

        /*
        <div className="howtostart">
            <div className="howtostart-wrapper">
                <div className="howtostart-preserver">
                    <div className="howtostart-content">
                        <h2 className="howtostart-header">How to start?</h2>
                        <img className="arrow-bar bar1"
                            src={Bar}/>
                        <img className="arrow-bar bar2"
                            src={Bar}/>
                        <img className="arrow-bar bar3"
                            src={Bar}/>
                        <img className="arrow-bar bar4"
                            src={Bar}/>
                        <div className="howtostart-icons">
                            <img className="icon-download"
                                src={IconDownload}/>
                            <img className="icon-rent"
                                src={IconRent}/>
                            <img className="icon-buy"
                                src={IconBuy}/>
                            <img className="icon-train"
                                src={IconTrain}/>
                        </div>
                        <div className="howtostart-texts">
                            <span className="text-download">
                                Download<br/> the application
                            </span>
                            <span className="text-rent">
                                Rent chosen NFT
                            </span>
                            <span className="text-buy">
                                Buy or lend your NFT
                            </span>
                            <span className="text-train">
                                Train !
                            </span>
                        </div>
                        <div className="howtostart-earn">
                            <img className="icon-earn"
                                src={IconEarn}/>
                        </div>
                        <h3 className="howtostart-subheader">
                            Earn the WRT tokens!
                        </h3>
                    </div>
                </div>
            </div>
        </div>*/
    )
}
