const IMAGES = [
    require("../../assets/intro/1.webp"),
    require("../../assets/intro/2.webp"),
    require("../../assets/intro/3.webp"),
    require("../../assets/intro/4.webp"),
    require("../../assets/intro/5.webp"),
    require("../../assets/intro/6.webp"),
    require("../../assets/intro/7.webp"),
    require("../../assets/intro/8.webp"),
    require("../../assets/intro/9.webp"),
    require("../../assets/intro/10.webp"),
    require("../../assets/intro/11.webp"),
    require("../../assets/intro/12.webp"),
    require("../../assets/intro/13.webp"),
    require("../../assets/intro/14.webp"),
    require("../../assets/intro/15.webp"),
    require("../../assets/intro/16.webp"),
    require("../../assets/intro/17.webp"),
    require("../../assets/intro/18.webp"),
    require("../../assets/intro/19.webp"),
    require("../../assets/intro/20.webp"),
    require("../../assets/intro/21.webp"),
    require("../../assets/intro/22.webp"),
    require("../../assets/intro/23.webp"),
    require("../../assets/intro/24.webp"),
    require("../../assets/intro/25.webp"),
    require("../../assets/intro/26.webp"),
    require("../../assets/intro/27.webp"),
    require("../../assets/intro/28.webp"),
    require("../../assets/intro/29.webp"),
    require("../../assets/intro/30.webp"),
    require("../../assets/intro/31.webp"),
    require("../../assets/intro/32.webp"),
    require("../../assets/intro/33.webp"),
    require("../../assets/intro/34.webp"),
    require("../../assets/intro/35.webp"),
    require("../../assets/intro/36.webp"),
    require("../../assets/intro/37.webp"),
    require("../../assets/intro/38.webp"),
    require("../../assets/intro/39.webp"),
    require("../../assets/intro/40.webp"),
    require("../../assets/intro/41.webp"),
    require("../../assets/intro/42.webp"),
    require("../../assets/intro/43.webp"),
    require("../../assets/intro/44.webp"),
    require("../../assets/intro/45.webp"),
    require("../../assets/intro/46.webp"),
    require("../../assets/intro/47.webp"),
    require("../../assets/intro/48.webp"),
    require("../../assets/intro/49.webp"),
    require("../../assets/intro/50.webp"),
]

export default IMAGES;
