const {REACT_APP_WORKOUT_API} = process.env;

export default class WorkoutApi {

    accessToken = null

    constructor() {
    }

    url(path) {
        return REACT_APP_WORKOUT_API + path
    }

    options(method) {
        return {
            method: method,
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
        }
    }

    async getStuff() {
        // let res = await fetch(this.url('/stuff'), this.options('GET'))
        // return await res.json()
        return [
            {name: 'Stuff', option: 1},
            {name: 'Stuff123', option: 2}
        ]
    }
}
