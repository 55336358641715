import React, {useState, useEffect, useRef} from 'react';
import Glow from "../../assets/shape/tokenomics_glow.webp";
import PieShadow from "../../assets/shape/tokenomics_pie_shadow.webp";
import PieToken from "../../assets/shape/tokenomics_pie_token.png";
import IcArrowUp from "../../assets/shape/ic_tokenomics_arrowup.webp";
import IcFlame from "../../assets/shape/ic_tokenomics_flame.webp";
import IcMoney from "../../assets/shape/ic_tokenomics_money.webp";
import IcKey from "../../assets/shape/ic_tokenomics_key.webp";
import IcRecovery from "../../assets/shape/ic_tokenomics_recovery.webp";
import IcArrowRight from "../../assets/shape/ic_tokenomics_arrowright.webp";
import PIE_IMAGES from "../../assets/shape/pie/pieImages.js";
import PIE_DOTS from "../../assets/shape/pie/pieDots.js";
import PIE_DOTS_ON from "../../assets/shape/pie/pieDotsOn.js";

import './Tokenomics.css';


export default function Tokenomics(props) {
    const [clickedTrain, setClickedTrain] = useState("");
    const [clickedLiquidity, setClickedLiquidity] = useState("");
    const [clickedMarketing, setClickedMarketing] = useState("");
    const [clickedMetaverse, setClickedMetaverse] = useState("");
    const [clickedPublic, setClickedPublic] = useState("");
    const [clickedTeam, setClickedTeam] = useState("");
    const [clickedAdvisors, setClickedAdvisors] = useState("");
    const [clickedPrivate, setClickedPrivate] = useState("");
    const [trainDot, setTrainDot] = useState(PIE_DOTS[0]);
    const [liquidityDot, setLiquidityDot] = useState(PIE_DOTS[1]);
    const [marketingDot, setMarketingDot] = useState(PIE_DOTS[2]);
    const [metaverseDot, setMetaverseDot] = useState(PIE_DOTS[3]);
    const [publicDot, setPublicDot] = useState(PIE_DOTS[4]);
    const [teamDot, setTeamDot] = useState(PIE_DOTS[5]);
    const [advisorsDot, setAdvisorsDot] = useState(PIE_DOTS[6]);
    const [privateDot, setPrivateDot] = useState(PIE_DOTS[7]);

    const animationIntervalLength = 600;
    const timeoutsQueue = useRef([]);

    useEffect(() => {
      return () => {
        clearTimeouts();
      };
    },[]);

    function clearTimeouts(){
        timeoutsQueue.current.forEach((item) => {
            clearTimeout(item);
        });
        timeoutsQueue.current = [];
    }

    props.biRef.tokenomics = {
        playAnimation: playAnimation,
        playAnimation2: playAnimation2,
    }

    function playAnimation() {
        console.log("tokenomics starts");
        props.biRef.main.enableScrollToTokenomics2();
        let header = document.querySelector(".tokenomics-header");
        let headerGlow = document.querySelector(".tokenomics-header-glow");
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                headerGlow.style.opacity = "1";
                header.style.top = "0%";
            }, animationIntervalLength
        );
    }

    function playAnimation2() {
        console.log("tokenomics2 starts");
        props.biRef.main.enableScrollToRoadmap();
        let nodeTexts = document.querySelectorAll(".tokenomics-node-text");
        let nodeIcons = document.querySelectorAll(".tokenomics-node-icon");
        let nodes = document.querySelectorAll(".tokenomics-node");
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                // nodeTexts[0].style.transform = "translateY(0%)";
                nodeTexts[0].style.top = "35%";
            }, animationIntervalLength
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                // nodeTexts[1].style.transform = "translateY(0%)";
                // nodeTexts[2].style.transform = "translateY(0%)";
                nodeTexts[1].style.top = "50%";
                nodeTexts[2].style.top = "35%";
            }, animationIntervalLength * 1
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                // nodeTexts[3].style.transform = "translateY(0%)";
                // nodeTexts[4].style.transform = "translateY(0%)";
                nodeTexts[3].style.top = "50%";
                nodeTexts[4].style.top = "35%";
            }, animationIntervalLength * 2
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                // nodeTexts[5].style.transform = "translateY(0%)";
                nodeTexts[5].style.top = "50%";
            }, animationIntervalLength * 3
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                nodeIcons[0].style.opacity = "1";
                nodeIcons[1].style.opacity = "1";
                nodeIcons[2].style.opacity = "1";
            }, animationIntervalLength * 4
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                // nodeTexts[7].style.transform = "translateY(0%)";
                nodeTexts[7].style.top = "35%";
            }, animationIntervalLength * 5
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                // nodeTexts[6].style.transform = "translateY(0%)";
                // nodeTexts[11].style.transform = "translateY(0%)";
                nodeTexts[6].style.top = "35%";
                nodeTexts[10].style.top = "35%";
            }, animationIntervalLength * 6
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                // nodeTexts[8].style.transform = "translateY(0%)";
                // nodeTexts[12].style.transform = "translateY(0%)";
                nodeTexts[8].style.top = "50%";
                nodeTexts[11].style.top = "62%";
            }, animationIntervalLength * 7
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                // nodeTexts[9].style.transform = "translateY(0%)";
                nodeTexts[9].style.top = "65%";
            }, animationIntervalLength * 8
        );
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                nodeIcons[3].style.opacity = "1";
                nodeIcons[4].style.opacity = "1";
                nodeIcons[5].style.opacity = "1";
            }, animationIntervalLength * 9
        );
    }

    function playClosingAnimation(){
        let nodes = document.querySelectorAll(".tokenomics-node");
        nodes[0].style.left = "-90%";
        nodes[3].style.left = "-90%";
        nodes[1].style.top = "50%";
        nodes[4].style.top = "55%";
        nodes[2].style.left = "150%";
        nodes[5].style.left = "150%";
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function(){
                nodes[0].style.left = "13%";
                nodes[3].style.left = "13%";
                nodes[1].style.top = "57%";
                nodes[4].style.top = "73%";
                nodes[2].style.left = "64%";
                nodes[5].style.left = "64%";
            }, animationIntervalLength * 20
        );
    }

    function toggleClickedTrain(){
        if(clickedTrain == ""){
            setClickedTrain("pie-piece-clicked");
            setTrainDot(PIE_DOTS_ON[0]);
        }else{
            setClickedTrain("");
            setTrainDot(PIE_DOTS[0]);
        }
    }

    function toggleClickedLiquidity(){
        if(clickedLiquidity == ""){
            setClickedLiquidity("pie-piece-clicked");
            setLiquidityDot(PIE_DOTS_ON[1]);
        }else{
            setClickedLiquidity("");
            setLiquidityDot(PIE_DOTS[1]);
        }
    }

    function toggleClickedMarketing(){
        if(clickedMarketing == ""){
            setClickedMarketing("pie-piece-clicked");
            setMarketingDot(PIE_DOTS_ON[2]);
        }else{
            setClickedMarketing("");
            setMarketingDot(PIE_DOTS[2]);
        }
    }

    function toggleClickedMetaverse(){
        if(clickedMetaverse == ""){
            setClickedMetaverse("pie-piece-clicked");
            setMetaverseDot(PIE_DOTS_ON[3]);
        }else{
            setClickedMetaverse("");
            setMetaverseDot(PIE_DOTS[3]);
        }
    }

    function toggleClickedPublic(){
        if(clickedPublic == ""){
            setClickedPublic("pie-piece-clicked");
            setPublicDot(PIE_DOTS_ON[4]);
        }else{
            setClickedPublic("");
            setPublicDot(PIE_DOTS[4]);
        }
    }

    function toggleClickedTeam(){
        if(clickedTeam == ""){
            setClickedTeam("pie-piece-clicked");
            setTeamDot(PIE_DOTS_ON[5]);
        }else{
            setClickedTeam("");
            setTeamDot(PIE_DOTS[5]);
        }
    }

    function toggleClickedAdvisors(){
        if(clickedAdvisors == ""){
            setClickedAdvisors("pie-piece-clicked");
            setAdvisorsDot(PIE_DOTS_ON[6]);
        }else{
            setClickedAdvisors("");
            setAdvisorsDot(PIE_DOTS[6]);
        }
    }

    function toggleClickedPrivate(){
        if(clickedPrivate == ""){
            setClickedPrivate("pie-piece-clicked");
            setPrivateDot(PIE_DOTS_ON[7]);
        }else{
            setClickedPrivate("");
            setPrivateDot(PIE_DOTS[7]);
        }
    }

    return (
        <div className="tokenomics">
            <div className="tokenomics-wrapper">
                <div className="tokenomics-preserver">
                    <div className="tokenomics-content">
                        <div className="tokenomics-part tokenomics-part1"></div>
                        <div className="tokenomics-header">
                            <img  alt="tokenomics" className="tokenomics-header-glow"
                                src={Glow}/>
                            <h1 className="tokenomics-header-text">tokenomics</h1>
                        </div>
                        <div className="pie-wrapper">
                            <div className="pie-preserver">
                                <div className="pie-content">
                                    <img  alt="tokenomics" className={"pie-piece pie-piece-train " + clickedTrain}
                                        src={ PIE_IMAGES[0] }/>
                                    <img  alt="tokenomics" className={"pie-piece pie-piece-liquidity " + clickedLiquidity}
                                        src={ PIE_IMAGES[1] }/>
                                    <img  alt="tokenomics" className={"pie-piece pie-piece-marketing " + clickedMarketing}
                                        src={ PIE_IMAGES[2] }/>
                                    <img  alt="tokenomics" className={"pie-piece pie-piece-metaverse " + clickedMetaverse}
                                        src={ PIE_IMAGES[3] }/>
                                    <img  alt="tokenomics" className={"pie-piece pie-piece-public " + clickedPublic}
                                        src={ PIE_IMAGES[4] }/>
                                    <img  alt="tokenomics" className={"pie-piece pie-piece-team " + clickedTeam}
                                        src={ PIE_IMAGES[5] }/>
                                    <img  alt="tokenomics" className={"pie-piece pie-piece-advisors " + clickedAdvisors}
                                        src={ PIE_IMAGES[6] }/>
                                    <img  alt="tokenomics" className={"pie-piece pie-piece-private " + clickedPrivate}
                                        src={ PIE_IMAGES[7] }/>
                                    <img  alt="tokenomics" className="pie-shadow"
                                        src={ PieShadow }/>
                                    <img  alt="tokenomics" className="pie-token"
                                        src={ PieToken }/>
                                    <div className="pie-button pie-button-train"
                                        onClick={()=>toggleClickedTrain()}></div>
                                    <div className="pie-button pie-button-liquidity"
                                        onClick={()=>toggleClickedLiquidity()}></div>
                                    <div className="pie-button pie-button-marketing"
                                        onClick={()=>toggleClickedMarketing()}></div>
                                    <div className="pie-button pie-button-metaverse"
                                        onClick={()=>toggleClickedMetaverse()}></div>
                                    <div className="pie-button pie-button-public"
                                        onClick={()=>toggleClickedPublic()}></div>
                                    <div className="pie-button pie-button-team"
                                        onClick={()=>toggleClickedTeam()}></div>
                                    <div className="pie-button pie-button-advisors"
                                        onClick={()=>toggleClickedAdvisors()}></div>
                                    <div className="pie-button pie-button-private"
                                        onClick={()=>toggleClickedPrivate()}></div>
                                </div>
                            </div>
                        </div>
                        <div className="tokenomics-legend">
                            <div className={"tokenomics-legend-row " + clickedTrain}
                                onClick={()=>toggleClickedTrain()}>
                                <img  alt="tokenomics" className="tokenomics-legend-dot"
                                    src={ trainDot }/>
                                <p className="tokenomics-legend-text tokenomics-legend-text-train">
                                    30% Train2Earn
                                </p>
                            </div>
                            <div className={"tokenomics-legend-row " + clickedLiquidity}
                                onClick={()=>toggleClickedLiquidity()}>
                                <img  alt="tokenomics" className="tokenomics-legend-dot"
                                    src={ liquidityDot }/>
                                <p className="tokenomics-legend-text tokenomics-legend-text-liquidity">
                                    20% Liquidity
                                </p>
                            </div>
                            <div className={"tokenomics-legend-row " + clickedMarketing}
                                onClick={()=>toggleClickedMarketing()}>
                                <img  alt="tokenomics" className="tokenomics-legend-dot"
                                    src={ marketingDot }/>
                                <p className="tokenomics-legend-text tokenomics-legend-text-marketing">
                                    15% Marketing
                                </p>
                            </div>
                            <div className={"tokenomics-legend-row " + clickedMetaverse}
                                onClick={()=>toggleClickedMetaverse()}>
                                <img  alt="tokenomics" className="tokenomics-legend-dot"
                                    src={ metaverseDot }/>
                                <p className="tokenomics-legend-text tokenomics-legend-text-metaverse">
                                    10% Development in Metaverse
                                </p>
                            </div>
                            <div className={"tokenomics-legend-row " + clickedPublic}
                                onClick={()=>toggleClickedPublic()}>
                                <img  alt="tokenomics" className="tokenomics-legend-dot"
                                    src={ publicDot }/>
                                <p className="tokenomics-legend-text tokenomics-legend-text-public">
                                    10% Public Sale
                                </p>
                            </div>
                            <div className={"tokenomics-legend-row " + clickedTeam}
                                onClick={()=>toggleClickedTeam()}>
                                <img  alt="tokenomics" className="tokenomics-legend-dot"
                                    src={ teamDot }/>
                                <p className="tokenomics-legend-text tokenomics-legend-text-team">
                                    10% Team
                                </p>
                            </div>
                            <div className={"tokenomics-legend-row " + clickedAdvisors}
                                onClick={()=>toggleClickedAdvisors()}>
                                <img  alt="tokenomics" className="tokenomics-legend-dot"
                                    src={ advisorsDot }/>
                                <p className="tokenomics-legend-text tokenomics-legend-text-advisors">
                                    3% Advisors
                                </p>
                            </div>
                            <div className={"tokenomics-legend-row " + clickedPrivate}
                                onClick={()=>toggleClickedPrivate()}>
                                <img  alt="tokenomics" className="tokenomics-legend-dot"
                                    src={ privateDot }/>
                                <p className="tokenomics-legend-text tokenomics-legend-text-private">
                                    2% Private Sale
                                </p>
                            </div>
                        </div>

                        <div className="tokenomics-part tokenomics-part2"></div>

                        <div className="tokenomics-node tokenomics-node-arrowup">
                            <img  alt="tokenomics" className="tokenomics-node-icon"
                                src={IcArrowUp}/>
                            <p className="tokenomics-node-text">One deflationary token with total<br/>supply of 15,000,000,000 WRT</p>
                            <p className="tokenomics-node-text">The final number of tokens will<br/>reach 5 billion</p>
                        </div>
                        <div className="tokenomics-node tokenomics-node-flame">
                            <img  alt="tokenomics" className="tokenomics-node-icon"
                                src={IcFlame}/>
                            <p className="tokenomics-node-text">Burn 0.01% for each NFT transaction</p>
                            <p className="tokenomics-node-text">Systematic token burning every 6<br/>months</p>
                        </div>
                        <div className="tokenomics-node tokenomics-node-money">
                            <img  alt="tokenomics" className="tokenomics-node-icon"
                                src={IcMoney}/>
                            <p className="tokenomics-node-text">Buy-back to assure the<br/>sustainability of the project</p>
                            <p className="tokenomics-node-text">Not less than 5% of the fee will be<br/>returned to the ecosystem</p>
                        </div>
                        <div className="tokenomics-node tokenomics-node-key">
                            <img  alt="tokenomics" className="tokenomics-node-icon"
                                src={IcKey}/>
                            <p className="tokenomics-node-text">The WRT token<br/>will be used to<br/>manage the<br/>entire<br/>Ecosystem</p>
                        </div>
                        <div className="tokenomics-node tokenomics-node-recovery">
                            <img  alt="tokenomics" className="tokenomics-node-icon"
                                src={IcRecovery}/>
                            <p className="tokenomics-node-text">Recovery before the next workout</p>
                            <p className="tokenomics-node-text">Receiving WRT tokens for workouts</p>
                            <p className="tokenomics-node-text">Buying a next level NFT or a new<br/>sport NFT</p>
                        </div>
                        <div className="tokenomics-node tokenomics-node-arrowright">
                            <img  alt="tokenomics" className="tokenomics-node-icon"
                                src={IcArrowRight}/>
                            <p className="tokenomics-node-text">In further stages of the project,<br/>payments with tokens for the gym<br/>membership cards in real world<br/>and in the Metaverse.</p>
                            <p className="tokenomics-node-text">Until the end of Q1 2023, payments<br/>in WRT tokens for personal trainings<br/>in VR</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
