import React, {useEffect} from 'react';
import { scrollIntoView } from "seamless-scroll-polyfill";
import NavBar from "../../components/NavBar/NavBar";
import SocialWidget from "../../components/SocialWidget/SocialWidget";
import PromoBanner from "../../components/PromoBanner/PromoBanner";
import Phones from "../../components/Phones/Phones";
import HowToStart from "../../components/HowToStart/HowToStart";
import Sports from "../../components/Sports/Sports";
import NFT from "../../components/NFT/NFT";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import Roadmap from "../../components/Roadmap/Roadmap";
import Team from "../../components/Team/Team";
import Footer from "../../components/Footer/Footer";

import './WRTMain.css';


export default function WRTMain(props) {
    useEffect(() => {
        disableScroll();
        window.scrollTo(0,0);
        // if (document.readyState === 'complete') {
        //     console.log("loaded already");
        //     biRef.promobanner.playAnimation();
        // } else {
        //     console.log("adding listener");
        //     window.addEventListener('load', biRef.promobanner.playAnimation);
        //     return () => window.removeEventListener('load', biRef.promobanner.playAnimation);
        // }
        let intervalId = setInterval(
            function() {
                // console.log("checking readiness");
                if(checkReadiness()){
                    // console.log("ready");
                    clearInterval(intervalId);
                    setTimeout(()=>{
                        document.querySelector(".intro-load").style.display = "none";
                        biRef.promobanner.playAnimation();
                        // biRef.promobanner.skipToClosing();
                    }, 500);
                }else{
                    // console.log("not ready");
                }
            }, 100
        )
        // enableScrollToPhones();
    },[]);

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = {37: 1, 38: 1, 39: 1, 40: 1};

    var biRef = {
      main: {
          disableScroll: disableScroll,
          enableScroll: enableScroll,
          scrollToPhones: scrollToPhones,
          enableScrollToPhones: enableScrollToPhones,
          disableScrollToPhones: disableScrollToPhones,
          scrollToHowToStart: scrollToHowToStart,
          enableScrollToHowToStart: enableScrollToHowToStart,
          enableScrollToSports: enableScrollToSports,
          scrollToSports: scrollToSports,
          scrollToNFT1: scrollToNFT1,
          enableScrollToNFT1: enableScrollToNFT1,
          scrollToNFT2: scrollToNFT2,
          enableScrollToNFT2: enableScrollToNFT2,
          scrollToNFT3: scrollToNFT3,
          enableScrollToNFT3: enableScrollToNFT3,
          scrollToTokenomics1: scrollToTokenomics1,
          enableScrollToTokenomics1: enableScrollToTokenomics1,
          scrollToTokenomics2: scrollToTokenomics2,
          enableScrollToTokenomics2: enableScrollToTokenomics2,
          scrollToRoadmap: scrollToRoadmap,
          justScrollToRoadmap: justScrollToRoadmap,
          enableScrollToRoadmap: enableScrollToRoadmap,
          scrollToTeam: scrollToTeam,
          enableScrollToTeam: enableScrollToTeam,
      }
    }

    function checkReadiness(){
        let images = document.querySelectorAll("img");
        // console.log("images " + images.length);
        let readyCount = 0;
        images.forEach((item, i) => {
            if(item.complete){ readyCount++ }
        });
        // console.log("ready " + readyCount);
        if(readyCount < images.length && images.length != 0){
            document.querySelector(".intro-load").textContent = Math.floor( (readyCount/images.length)*100 ) + "%";
            return false;
        }else{
            document.querySelector(".intro-load").textContent = "100%";
            return true;
        }
    }

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    function scrollToPhones(e){
        let prevSection = document.querySelector(".promo-banner");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToPhones();
        scrollToSection(".phones");
        biRef.promobanner.playClosingAnimation();
        biRef.phones.playAnimation();
    }

    function scrollToPhonesForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToPhones(e);
          return false;
        }
    }

    function scrollToHowToStart() {
        let prevSection = document.querySelector(".phones");
        // let prevSection = document.querySelector(".promo-banner");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToHowToStart();
        scrollToSection(".howtostart");
        biRef.phones.playClosingAnimation();
        biRef.howtostart.playAnimation();
    }

    function scrollToHowToStartForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToHowToStart(e);
          return false;
        }
    }

    function scrollToSports() {
        let prevSection = document.querySelector(".howtostart");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToSports();
        scrollToSection(".sports");
        biRef.howtostart.playClosingAnimation();
        biRef.sports.playAnimation();
    }

    function scrollToSportsForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToSports(e);
          return false;
        }
    }

    function scrollToNFT1(){
        let prevSection = document.querySelector(".sports");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToNFT1();
        scrollToSection(".nft-part1");
        biRef.sports.playClosingAnimation();
        biRef.nft.playAnimation();
    }

    function scrollToNFT1ForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToNFT1(e);
          return false;
        }
    }

    function scrollToNFT2(){
        let prevSection = document.querySelector(".nft-part1");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToNFT2();
        scrollToSection(".nft-part2");
        biRef.nft.playAnimation2();
    }

    function scrollToNFT2ForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToNFT2(e);
          return false;
        }
    }

    function scrollToNFT3(){
        let prevSection = document.querySelector(".nft-part2");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToNFT3();
        scrollToSection(".nft-part3");
        biRef.nft.playAnimation3();
    }

    function scrollToNFT3ForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToNFT3(e);
          return false;
        }
    }

    function scrollToTokenomics1(){
        let prevSection = document.querySelector(".nft-part3");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToTokenomics1();
        scrollToSection(".tokenomics-part1");
        biRef.tokenomics.playAnimation();
    }

    function scrollToTokenomics1ForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToTokenomics1(e);
          return false;
        }
    }

    function scrollToTokenomics2(){
        let prevSection = document.querySelector(".tokenomics-part1");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToTokenomics2();
        scrollToSection(".tokenomics-part2");
        biRef.tokenomics.playAnimation2();
    }

    function scrollToTokenomics2ForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToTokenomics2(e);
          return false;
        }
    }

    function scrollToRoadmap(){
        let prevSection = document.querySelector(".tokenomics-part2");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToRoadmap();
        scrollToSection(".roadmap");
        biRef.roadmap.playAnimation();
    }

    function justScrollToRoadmap(){
        scrollToSection(".roadmap");
    }

    function scrollToRoadmapForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToRoadmap(e);
          return false;
        }
    }

    function scrollToTeam(){
        let prevSection = document.querySelector(".roadmap");
        let prevPos = prevSection.getBoundingClientRect().top;
        let offset = prevSection.getBoundingClientRect().height * .1;
        if(prevPos + offset > 0){
            return;
        }
        disableScrollToTeam();
        scrollToSection(".team");
        biRef.roadmap.playClosingAnimation();
    }

    function scrollToTeamForScrollKeys(e) {
        if (keys[e.keyCode]) {
          scrollToTeam(e);
          return false;
        }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
      }));
    } catch(e) {}

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    // function disableScroll() {
    //   document.body.style.overflow = "hidden";
    // }
    //
    // function enableScroll() {
    //   document.body.style.overflow = "";
    // }

    function disableScroll() {
        window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    function enableScroll() {
        window.removeEventListener('DOMMouseScroll', preventDefault, false); // older FF
        window.removeEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        window.removeEventListener('touchmove', preventDefault, wheelOpt); // mobile
        window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    function scrollToSection(sectionName){
      var sectiontarget = document.querySelector(sectionName);
      var bodyrect = document.body.getBoundingClientRect().top;
      var targetposition = sectiontarget.getBoundingClientRect().top;
      var elementposition = targetposition - bodyrect;
      scrollSmoothly(elementposition);
    }

    function scrollSmoothly(pos){
      var currentPos = window.scrollY||window.screenTop;
      if(currentPos<pos){
        var t = 10;
        for(let i = currentPos; i <= pos; i+=10){
          t+=10;
          setTimeout(function(){
         	  window.scrollTo(0, i);
          }, t/2);
        }
      }else{
        var time = 2;
        var i = currentPos;
        var x = setInterval(function(){
          window.scrollTo(0, i);
          i -= 10;
          if(i<=pos){
           clearInterval(x);
          }
        }, time);
      }
    }

    function enableScrollToPhones() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToPhones, false); // older FF
      window.addEventListener(wheelEvent, scrollToPhones, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToPhones, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToPhonesForScrollKeys, false);
    }

    function disableScrollToPhones() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToPhones, false); // older FF
      window.removeEventListener(wheelEvent, scrollToPhones, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToPhones, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToPhonesForScrollKeys, false);
    }

    function enableScrollToHowToStart() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToHowToStart, false); // older FF
      window.addEventListener(wheelEvent, scrollToHowToStart, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToHowToStart, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToHowToStartForScrollKeys, false);
    }

    function disableScrollToHowToStart() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToHowToStart, false); // older FF
      window.removeEventListener(wheelEvent, scrollToHowToStart, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToHowToStart, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToHowToStartForScrollKeys, false);
    }

    function enableScrollToSports() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToSports, false); // older FF
      window.addEventListener(wheelEvent, scrollToSports, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToSports, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToSportsForScrollKeys, false);
    }

    function disableScrollToSports() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToSports, false); // older FF
      window.removeEventListener(wheelEvent, scrollToSports, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToSports, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToSportsForScrollKeys, false);
    }

    function enableScrollToNFT1() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToNFT1, false); // older FF
      window.addEventListener(wheelEvent, scrollToNFT1, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToNFT1, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToNFT1ForScrollKeys, false);
    }

    function disableScrollToNFT1() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToNFT1, false); // older FF
      window.removeEventListener(wheelEvent, scrollToNFT1, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToNFT1, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToNFT1ForScrollKeys, false);
    }

    function enableScrollToNFT2() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToNFT2, false); // older FF
      window.addEventListener(wheelEvent, scrollToNFT2, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToNFT2, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToNFT2ForScrollKeys, false);
    }

    function disableScrollToNFT2() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToNFT2, false); // older FF
      window.removeEventListener(wheelEvent, scrollToNFT2, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToNFT2, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToNFT2ForScrollKeys, false);
    }

    function enableScrollToNFT3() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToNFT3, false); // older FF
      window.addEventListener(wheelEvent, scrollToNFT3, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToNFT3, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToNFT3ForScrollKeys, false);
    }

    function disableScrollToNFT3() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToNFT3, false); // older FF
      window.removeEventListener(wheelEvent, scrollToNFT3, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToNFT3, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToNFT3ForScrollKeys, false);
    }

    function enableScrollToTokenomics1() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToTokenomics1, false); // older FF
      window.addEventListener(wheelEvent, scrollToTokenomics1, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToTokenomics1, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToTokenomics1ForScrollKeys, false);
    }

    function disableScrollToTokenomics1() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToTokenomics1, false); // older FF
      window.removeEventListener(wheelEvent, scrollToTokenomics1, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToTokenomics1, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToTokenomics1ForScrollKeys, false);
    }

    function enableScrollToTokenomics2() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToTokenomics2, false); // older FF
      window.addEventListener(wheelEvent, scrollToTokenomics2, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToTokenomics2, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToTokenomics2ForScrollKeys, false);
    }

    function disableScrollToTokenomics2() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToTokenomics2, false); // older FF
      window.removeEventListener(wheelEvent, scrollToTokenomics2, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToTokenomics2, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToTokenomics2ForScrollKeys, false);
    }

    function enableScrollToRoadmap() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToRoadmap, false); // older FF
      window.addEventListener(wheelEvent, scrollToRoadmap, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToRoadmap, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToRoadmapForScrollKeys, false);
    }

    function disableScrollToRoadmap() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToRoadmap, false); // older FF
      window.removeEventListener(wheelEvent, scrollToRoadmap, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToRoadmap, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToRoadmapForScrollKeys, false);
    }

    function enableScrollToTeam() {
      console.log("scroll armed");
      window.addEventListener('DOMMouseScroll', scrollToTeam, false); // older FF
      window.addEventListener(wheelEvent, scrollToTeam, wheelOpt); // modern desktop
      window.addEventListener('touchmove', scrollToTeam, wheelOpt); // mobile
      window.addEventListener('keydown', scrollToTeamForScrollKeys, false);
    }

    function disableScrollToTeam() {
      console.log("scroll disarmed");
      window.removeEventListener('DOMMouseScroll', scrollToTeam, false); // older FF
      window.removeEventListener(wheelEvent, scrollToTeam, wheelOpt); // modern desktop
      window.removeEventListener('touchmove', scrollToTeam, wheelOpt); // mobile
      window.removeEventListener('keydown', scrollToTeamForScrollKeys, false);
    }


    return (
        <>
            <div className="wrtmain">
                <NavBar biRef={biRef}/>
                <SocialWidget biRef={biRef}/>
                <PromoBanner biRef={biRef}/>
                <Phones biRef={biRef}/>
                <HowToStart biRef={biRef}/>
                <Sports biRef={biRef}/>
                <NFT biRef={biRef}/>
                <Tokenomics biRef={biRef}/>
                <Roadmap biRef={biRef}/>
                <Team/>
                <Footer biRef={biRef}/>
            </div>
        </>
    )
}
