const IMAGES = [
    require("./dot_22_q1.webp"),
    require("./dot_22_q3.webp"),
    require("./dot_23_q1.webp"),
    require("./dot_23_q3.webp"),
    require("./dot_22_q2.webp"),
    require("./dot_22_q4.webp"),
    require("./dot_23_q2.webp"),
]

export default IMAGES;
