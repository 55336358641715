const IMAGES = [
    require("./30.webp"),
    require("./20.webp"),
    require("./15.webp"),
    require("./10_1.webp"),
    require("./10_2.webp"),
    require("./10_3.webp"),
    require("./3.webp"),
    require("./2.webp"),
]

export default IMAGES;
