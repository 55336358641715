import React from 'react';
import { Link } from "react-router-dom";
import './NavItem.css';

export default function NavItem(props){

    return(
        <div className={`navitem ${props.type}`}
            onClick={props.onClick}>
            <span>{props.text}</span>
            <Link to={props.link}/>
        </div>
    )
}
