import React, {useState, useRef} from 'react';
import Icon from "../../assets/shape/sw_icon.svg";
import Button from "../../assets/shape/sw_button.svg";
import DiscordIcon from "../../assets/shape/socialwidget/discord.svg";
import InstagramIcon from "../../assets/shape/socialwidget/instagram.svg";
import LinkedinIcon from "../../assets/shape/socialwidget/linkedin.svg";
import MessageIcon from "../../assets/shape/socialwidget/message.svg";
import TelegramIcon from "../../assets/shape/socialwidget/telegram.svg";
import TwitterIcon from "../../assets/shape/socialwidget/twitter.svg";

import './SocialWidget.css';

export default function SocialWidget(props){
    const [show, setShow] = useState("");
    const [buttonClass, setButtonClass] = useState("");
    const [modalClass, setModalClass] = useState("");
    const [containerClass, setContainerClass] = useState("");
    const widgetActive = useRef(false);

    props.biRef.socialwidget = {
        showSocialWidget: showSocialWidget,
        openSocialWidget: openSocialWidget,
    };

    function showSocialWidget() {
        setShow("socialwidget-wrapper-show");
    }

    function openSocialWidget() {
        setModalClass("socialwidget-modal-show");
        setButtonClass("socialwidget-button-middle");
        setTimeout(()=>{
            setContainerClass("socialwidget-container-show1");
        },500);
        setTimeout(()=>{
            setContainerClass("socialwidget-container-show2");
            setButtonClass("socialwidget-button-gone");
            widgetActive.current = true;
        },800)
    }

    function closeSocialWidget() {
        if(widgetActive.current == false){
            return;
        }
        widgetActive.current = false;
        setModalClass("");
        setButtonClass("socialwidget-button-middle");
        setContainerClass("");
        setTimeout(()=>{
            setButtonClass("");
        },500);
    }

    return(
        <div className={`socialwidget-wrapper wrtmain-section-wrapper ${show}`}>
            <div className="socialwidget wrtmain-section-content">
                <div className={`socialwidget-modal ${modalClass}`}
                    onClick={()=>closeSocialWidget()}></div>
                <div className={`socialwidget-button ${buttonClass}`}
                    style={{backgroundImage: "url(" + Button + ")"}}
                    onClick={()=>openSocialWidget()}>
                    <img  alt="widget" className="socialwidget-button-icon"
                        src={Icon}/>
                </div>
                <div className={`socialwidget-container ${containerClass}`}>
                    <a className="socialwidget-link link-twitter"
                        href="https://twitter.com/Workoutapp_io"
                        target="_blank">
                        <img alt="Twitter" src={TwitterIcon}/>
                    </a>
                    <a className="socialwidget-link link-linkedin"
                        href="https://www.linkedin.com/company/workoutappio/"
                        target="_blank">
                        <img  alt="Linkedin" src={LinkedinIcon}/>
                    </a>
                    <a className="socialwidget-link link-message"
                        href="https://open.kakao.com/o/gulUzixe"
                        target="_blank">
                        <img  alt="Message" src={MessageIcon}/>
                    </a>
                    <a className="socialwidget-link link-instagram"
                        href="https://www.instagram.com/workoutapp_io/"
                        target="_blank">
                        <img  alt="Instagram" src={InstagramIcon}/>
                    </a>
                    <a className="socialwidget-link link-telegram"
                        href="https://t.me/workoutapp"
                        target="_blank">
                        <img  alt="Telegram" src={TelegramIcon}/>
                    </a>
                    <a className="socialwidget-link link-discord"
                        href="https://discord.gg/EA86JGVj2a"
                        target="_blank">
                        <img  alt="Discord" src={DiscordIcon}/>
                    </a>
                </div>
            </div>
        </div>
    )
}
