const IMAGES = [
    require("./dot_train.webp"),
    require("./dot_liquidity.webp"),
    require("./dot_marketing.webp"),
    require("./dot_metaverse.webp"),
    require("./dot_public.webp"),
    require("./dot_team.webp"),
    require("./dot_advisors.webp"),
    require("./dot_private.webp"),
]

export default IMAGES;
