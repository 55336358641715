import React, {useState, useEffect, useRef} from 'react';
import BARS from "../../assets/shape/road/images.js";
import DOTS from "../../assets/shape/road_dots/images.js";
import Neon from "../../assets/shape/roadmap_neon.webp";
import Glow from "../../assets/shape/roadmap_glow.webp";
import Scar from "../../assets/shape/roadmap_scar.webp";
import ScarGlow from "../../assets/shape/roadmap_scar_glow.webp";

import './Roadmap.css';

export default function Roadmap(props) {
    const [header, setHeader] = useState("");
    const [glow, setGlow] = useState("dark");
    const [q122, setq122] = useState("");
    const [q222, setq222] = useState("");
    const [q322, setq322] = useState("");
    const [q422, setq422] = useState("");
    const [q123, setq123] = useState("");
    const [q223, setq223] = useState("");
    const [q323, setq323] = useState("");

    const [q423, setq423] = useState("");
    const [q124, setq124] = useState("");

    useEffect(() => {
        if(q122 == "" && q322 == "" && q123 == "" && q323 == "" && q124 == ""){
            setHeader("");
        }else{
            setHeader("active");
        }
    }, [q122, q322, q123, q323, q124]);

    const animationIntervalLength = 1000;
    const timeoutsQueue = useRef([]);

    useEffect(() => {
      return () => {
        clearTimeouts();
      };
    },[]);

    function clearTimeouts(){
        timeoutsQueue.current.forEach((item) => {
            clearTimeout(item);
        });
        timeoutsQueue.current = [];
    }

    props.biRef.roadmap = {
        playAnimation: playAnimation,
        playClosingAnimation: playClosingAnimation,
    }

    function playAnimation() {
        props.biRef.main.enableScrollToTeam();
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function() {
                setGlow("");
            },
            animationIntervalLength
        );
    }

    function playClosingAnimation() {
        setGlow("dark");
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function() {
                setGlow("");
            },
            animationIntervalLength
        );
    }

    function toggleQ122() {
        if(q122 == ""){
            setq122("active");
        }else{
            setq122("");
        }
    }

    function toggleQ222() {
        if(q222 == ""){
            setq222("active");
        }else{
            setq222("");
        }
    }

    function toggleQ322() {
        if(q322 == ""){
            setq322("active");
        }else{
            setq322("");
        }
    }

    function toggleQ422() {
        if(q422 == ""){
            setq422("active");
        }else{
            setq422("");
        }
    }

    function toggleQ123() {
        if(q123 == ""){
            setq123("active");
        }else{
            setq123("");
        }
    }

    function toggleQ223() {
        if(q223 == ""){
            setq223("active");
        }else{
            setq223("");
        }
    }

    function toggleQ323() {
        if(q323 == ""){
            setq323("active");
        }else{
            setq323("");
        }
    }

    function toggleQ423() {
        if(q423 == ""){
            setq423("active");
        }else{
            setq423("");
        }
    }

    function toggleQ124() {
        if(q124 == ""){
            setq124("active");
        }else{
            setq124("");
        }
    }
    /*
        <img className="roadmap-scar roadmap-scar1"
            src={Scar}/>
        <img className="roadmap-scar roadmap-scar2"
            src={Scar}/>
    */

    return(
        <div className="roadmap wrtmain-section-wrapper">
            <div className="roadmap-content wrtmain-section-content">
                <h1 className={"roadmap-header " + header}>roadmap</h1>
                <img alt="roadmap" className="roadmap-scar roadmap-scar1"
                    src={Scar}/>
                <img alt="roadmap" className="roadmap-scar roadmap-scar2"
                    src={Scar}/>
                <img alt="roadmap" className={"roadmap-glow " + glow}
                    src={ScarGlow}/>

                <div className="roadmap-container roadmap-container-top">
                    <div className={"roadmap-drawer roadmap-drawer-1 " + q122}
                        onClick={()=>toggleQ122()}>
                        <div className="roadmap-drawer-header">
                            <img alt="roadmap" className="roadmap-drawer-header-dot"
                                src={ DOTS[0] }/>
                            <span className="roadmap-drawer-header-quarter">Q1</span>
                            <span className="roadmap-drawer-header-year">2022</span>
                        </div>
                        <img alt="roadmap" className="roadmap-drawer-bar"
                            src={ BARS[0] }/>

                        <p className="roadmap-drawer-par">The idea of creating the WorkoutApp</p>
                        <p className="roadmap-drawer-par">Gathering a team</p>
                        <p className="roadmap-drawer-par">Market research</p>
                        <p className="roadmap-drawer-par">Development of a marketing strategy</p>
                    </div>



                    <div className={"roadmap-drawer roadmap-drawer-2 " + q322}
                        onClick={()=>toggleQ322()}>
                        <div className="roadmap-drawer-header">
                            <img alt="roadmap" className="roadmap-drawer-header-dot"
                                src={ DOTS[1] }/>
                            <span className="roadmap-drawer-header-quarter">Q3</span>
                            <span className="roadmap-drawer-header-year">2022</span>
                        </div>
                        <img alt="roadmap" className="roadmap-drawer-bar"
                            src={ BARS[1] }/>
                            <p className="roadmap-drawer-par">Building and engaging the community</p>
                            <p className="roadmap-drawer-par">Collaboration with athletes</p>
                            <p className="roadmap-drawer-par">consultations with partners and experts</p>
                            <p className="roadmap-drawer-par">Marketing strategy adjustments</p>
                            <p className="roadmap-drawer-par">Definition of WorkoutApp requirements</p>

                    </div>

                    <div className={"roadmap-drawer roadmap-drawer-3 " + q123}
                        onClick={()=>toggleQ123()}>
                        <div className="roadmap-drawer-header">
                            <img alt="roadmap" className="roadmap-drawer-header-dot"
                                src={ DOTS[2] }/>
                            <span className="roadmap-drawer-header-quarter">Q1</span>
                            <span className="roadmap-drawer-header-year">2023</span>
                        </div>
                        <img alt="roadmap" className="roadmap-drawer-bar"
                            src={ BARS[2] }/>
                            <p className="roadmap-drawer-par">Presentation of the NFT collection</p>
                            <p className="roadmap-drawer-par">Creation of an MVP</p>
                            <p className="roadmap-drawer-par">Application tests</p>
                            <p className="roadmap-drawer-par">In-app marketplace</p>
                            <p className="roadmap-drawer-par">Alpha application introduction</p>
                            <p className="roadmap-drawer-par">Application optimizing</p>


                    </div>

                    <div className={"roadmap-drawer roadmap-drawer-4 " + q323}
                        onClick={()=>toggleQ323()}>
                        <div className="roadmap-drawer-header">
                            <img alt="roadmap" className="roadmap-drawer-header-dot"
                                src={ DOTS[3] }/>
                            <span className="roadmap-drawer-header-quarter">Q3</span>
                            <span className="roadmap-drawer-header-year">2023</span>
                        </div>
                        <img  alt="roadmap"className="roadmap-drawer-bar"
                            src={ BARS[3] }/>

                            <p className="roadmap-drawer-par">Introduction of Workout Wallet</p>
                            <p className="roadmap-drawer-par">Passive earning and project investments</p>
                            <p className="roadmap-drawer-par">Classification of the most active users</p>
                            <p className="roadmap-drawer-par">Launch of the NFT lending</p>
                            <p className="roadmap-drawer-par">Enable token swapping in the application</p>

                    </div>

                    <div className={"roadmap-drawer roadmap-drawer-4A " + q124}
                        onClick={()=>toggleQ124()}>
                        <div className="roadmap-drawer-header">
                            <img alt="roadmap" className="roadmap-drawer-header-dot"
                                src={ DOTS[4] }/>
                            <span className="roadmap-drawer-header-quarter">Q1</span>
                            <span className="roadmap-drawer-header-year">2024</span>
                        </div>
                        <img alt="roadmap" className="roadmap-drawer-bar"
                            src={ BARS[4] }/>

                            <p className="roadmap-drawer-par">Work on integration with existing Metaverses</p>
                            <p className="roadmap-drawer-par">Introduction of VR personal training</p>
                            <p className="roadmap-drawer-par">Integration with the most popular Meta-worlds</p>
                            <p className="roadmap-drawer-par">Creating own WorkoutGym network in the most popular Metaverse</p>

                    </div>

                </div>

                <div className="roadmap-container roadmap-container-bottom">
                    <div className={"roadmap-drawer roadmap-drawer-5 " + q222}
                        onClick={()=>toggleQ222()}>
                        <div className="roadmap-drawer-header">
                            <img  alt="roadmap"className="roadmap-drawer-header-dot"
                                src={ DOTS[4] }/>
                            <span className="roadmap-drawer-header-quarter">Q2</span>
                            <span className="roadmap-drawer-header-year">2022</span>
                        </div>
                        <img  alt="roadmap"className="roadmap-drawer-bar"
                            src={ BARS[4] }/>
                            <p className="roadmap-drawer-par">Creation of a website v1</p>
                            <p className="roadmap-drawer-par">1st round of private sale</p>
                            <p className="roadmap-drawer-par">Establishing contacts with partners</p>

                    </div>

                    <div className={"roadmap-drawer roadmap-drawer-6 " + q422}
                        onClick={()=>toggleQ422()}>
                        <div className="roadmap-drawer-header">
                            <img alt="roadmap" className="roadmap-drawer-header-dot"
                                src={ DOTS[5] }/>
                            <span className="roadmap-drawer-header-quarter">Q4</span>
                            <span className="roadmap-drawer-header-year">2022</span>
                        </div>
                        <img  alt="roadmap"className="roadmap-drawer-bar"
                            src={ BARS[5] }/>

                            <p className="roadmap-drawer-par">application development</p>
                            <p className="roadmap-drawer-par">Cooperation with sports brands</p>
                            <p className="roadmap-drawer-par">Refining the tokenomics</p>
                            <p className="roadmap-drawer-par">Creating a website v2</p>
                            <p className="roadmap-drawer-par">2nd round of private sale</p>

                    </div>

                    <div className={"roadmap-drawer roadmap-drawer-7 " + q223}
                        onClick={()=>toggleQ223()}>
                        <div className="roadmap-drawer-header">
                            <img alt="roadmap" className="roadmap-drawer-header-dot"
                                src={ DOTS[6] }/>
                            <span className="roadmap-drawer-header-quarter">Q2</span>
                            <span className="roadmap-drawer-header-year">2023</span>
                        </div>
                        <img  alt="roadmap"className="roadmap-drawer-bar"
                            src={ BARS[6] }/>

                          <p className="roadmap-drawer-par">Refining the remuneration algorithms</p>
                          <p className="roadmap-drawer-par">Audit</p>
                          <p className="roadmap-drawer-par">Beta application introduction</p>
                          <p className="roadmap-drawer-par">Presale</p>
                          <p className="roadmap-drawer-par">Listing on Tier 1 CEX & DEX</p>
                          <p className="roadmap-drawer-par">Start of staking</p>
                          <p className="roadmap-drawer-par">Application introduction</p>

                    </div>

                    <div className={"roadmap-drawer roadmap-drawer-7A " + q423}
                        onClick={()=>toggleQ423()}>
                        <div className="roadmap-drawer-header">
                            <img  alt="roadmap"className="roadmap-drawer-header-dot"
                                src={ DOTS[0] }/>
                            <span className="roadmap-drawer-header-quarter">Q4</span>
                            <span className="roadmap-drawer-header-year">2023</span>
                        </div>
                        <img  alt="roadmap"className="roadmap-drawer-bar"
                            src={ BARS[0] }/>

                            <p className="roadmap-drawer-par">Introduction of WorkoutApp Crypto League</p>
                            <p className="roadmap-drawer-par">Partnerships with gyms in the real world</p>
                            <p className="roadmap-drawer-par">Organization of WorkoutApp.io competitions</p>
                            <p className="roadmap-drawer-par">Land purchase in the Metaverse for the first WorkoutGym</p>

                    </div>
                </div>
            </div>
        </div>
    )
}
