import React, {useState, useEffect, useRef} from 'react';
import Neon from "../../assets/shape/neon.webp";
import CROSSFIT_IMAGES from "../../assets/gif/crossfit/images.js";
import CYCLING_IMAGES from "../../assets/gif/cycling/images.js";
import GENERAL_IMAGES from "../../assets/gif/general/images.js";
import INTERVAL_IMAGES from "../../assets/gif/interval/images.js";
import RUNNING_IMAGES from "../../assets/gif/running/images.js";
import STRENGTH_IMAGES from "../../assets/gif/strength/images.js";
import SWIMMING_IMAGES from "../../assets/gif/swimming/images.js";
import TENNIS_IMAGES from "../../assets/gif/tennis/images.js";
import WALKING_IMAGES from "../../assets/gif/walking/images.js";
import YOGA_IMAGES from "../../assets/gif/yoga/images.js";

import './Sports.css';

export default function Sports(props) {
    const [crossfitImage, setCrossfitImage] = useState(CROSSFIT_IMAGES[0]);
    const [cyclingImage, setCyclingImage] = useState(CYCLING_IMAGES[0]);
    const [generalImage, setGeneralImage] = useState(GENERAL_IMAGES[0]);
    const [intervalImage, setIntervalImage] = useState(INTERVAL_IMAGES[0]);
    const [runningImage, setRunningImage] = useState(RUNNING_IMAGES[0]);
    const [strengthImage, setStrengthImage] = useState(STRENGTH_IMAGES[0]);
    const [swimmingImage, setSwimmingImage] = useState(SWIMMING_IMAGES[0]);
    const [tennisImage, setTennisImage] = useState(TENNIS_IMAGES[0]);
    const [walkingImage, setWalkingImage] = useState(WALKING_IMAGES[0]);
    const [yogaImage, setYogaImage] = useState(YOGA_IMAGES[0]);

    const [headerClass, setHeaderClass] = useState("sports-header-initial");
    const [categoriesClass, setCategoriesClass] = useState("sports-categories-initial");

    const timeoutsQueue = useRef([]);
    const intervalId = useRef(null);
    const animationIntervalLength = 700;

    props.biRef.sports = {
        playAnimation: playAnimation,
        playClosingAnimation: playClosingAnimation,
    }

    useEffect(() => {
      return () => {
        clearTimeouts();
      };
    },[]);

    function clearTimeouts(){
        timeoutsQueue.current.forEach((item) => {
            clearTimeout(item);
        });
        clearInterval(intervalId.current);
        timeoutsQueue.current = [];
        intervalId.current = null;
    }

    function playAnimation(){
        console.log("Sports starts");
        props.biRef.main.enableScrollToNFT1();
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setHeaderClass("");
            setCategoriesClass("");
            startGifs();
        }, 500);

        setHeaderClass("sports-header-initial");
        setCategoriesClass("sports-categories-initial");
    }

    function playClosingAnimation(){
        setHeaderClass("sports-header-initial");
        setCategoriesClass("sports-categories-initial");
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setHeaderClass("");
            setCategoriesClass("");
        },1000);
    }

    function startGifs(){
        let gifIndex = 0;
        intervalId.current = setInterval(
            function(){
                gifIndex++;
                setCrossfitImage(CROSSFIT_IMAGES[gifIndex%CROSSFIT_IMAGES.length]);
                setCyclingImage(CYCLING_IMAGES[gifIndex%CYCLING_IMAGES.length]);
                setGeneralImage(GENERAL_IMAGES[gifIndex%GENERAL_IMAGES.length]);
                setIntervalImage(INTERVAL_IMAGES[gifIndex%INTERVAL_IMAGES.length]);
                setRunningImage(RUNNING_IMAGES[gifIndex%RUNNING_IMAGES.length]);
                setStrengthImage(STRENGTH_IMAGES[gifIndex%STRENGTH_IMAGES.length]);
                setSwimmingImage(SWIMMING_IMAGES[gifIndex%SWIMMING_IMAGES.length]);
                setTennisImage(TENNIS_IMAGES[gifIndex%TENNIS_IMAGES.length]);
                setWalkingImage(WALKING_IMAGES[gifIndex%WALKING_IMAGES.length]);
                setYogaImage(YOGA_IMAGES[gifIndex%YOGA_IMAGES.length]);
            }, animationIntervalLength
        );
    }

    return(
        <div className="sports wrtmain-section-wrapper">
            <div className="sports-content wrtmain-section-content">
                <img alt="sports" className="sports-neon"
                    src={Neon}/>
                <p className="sports-text">
                The application recognizes the <b>type of activity</b> performed on the basis of readings from<br/>wearables and the phone, thanks to which no one is limited only to walking or running.
                </p>
                <h2 className={`sports-header ${headerClass}`}
                >there are<br/>many<br/>categories<br/>of<br/>sports to<br/>choose<br/>from</h2>
                <div className={`sports-categories ${categoriesClass}`}>

                    <img  alt="sports" className="sports-gif sports-gif-walking"
                        src={ walkingImage }/>
                    <img  alt="sports" className="sports-gif sports-gif-running"
                        src={ runningImage }/>
                    <img  alt="sports" className="sports-gif sports-gif-swimming"
                        src={ swimmingImage }/>
                    <img  alt="sports" className="sports-gif sports-gif-cycling"
                        src={ cyclingImage }/>
                    <img  alt="sports" className="sports-gif sports-gif-crossfit"
                        src={ crossfitImage }/>
                    <img  alt="sports" className="sports-gif sports-gif-tennis"
                        src={ tennisImage }/>
                    <img  alt="sports" className="sports-gif sports-gif-yoga"
                        src={ yogaImage }/>
                    <img  alt="sports" className="sports-gif sports-gif-general"
                        src={ generalImage }/>
                    <img  alt="sports" className="sports-gif sports-gif-strength"
                        src={ strengthImage }/>

                    <img  alt="sports" className="sports-gif sports-gif-interval"
                            src={ intervalImage }/>

                    <p className="sports-name sports-name-walking">walking</p>
                    <p className="sports-name sports-name-running">running</p>
                    <p className="sports-name sports-name-swimming">swimming</p>
                    <p className="sports-name sports-name-cycling">cycling</p>
                    <p className="sports-name sports-name-crossfit">crossfit</p>
                    <p className="sports-name sports-name-tennis">tennis</p>
                    <p className="sports-name sports-name-yoga">yoga/pilates</p>
                    <p className="sports-name sports-name-general">general workout</p>
                    <p className="sports-name sports-name-strength">strength training</p>
                    <p className="sports-name sports-name-interval">interval training</p>
                </div>
            </div>
        </div>
    )
}
/*
    <div className="sports-categories">
        <div className="sports-category">
            <img className="sports-gif"
                src={ walkingImage }/>
            <p className="sports-name">walking</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ runningImage }/>
            <p className="sports-name">running</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ swimmingImage }/>
            <p className="sports-name">swimming</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ cyclingImage }/>
            <p className="sports-name">cycling</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ crossfitImage }/>
            <p className="sports-name">crossfit</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ tennisImage }/>
            <p className="sports-name">tennis</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ yogaImage }/>
            <p className="sports-name">yoga/pilates</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ generalImage }/>
            <p className="sports-name">general workout</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ strengthImage }/>
            <p className="sports-name">strength training</p>
        </div>
        <div className="sports-category">
            <img className="sports-gif"
                src={ intervalImage }/>
            <p className="sports-name">interval training</p>
        </div>
    </div>
*/
