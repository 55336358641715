import React, {useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import NavItem from "../NavItem/NavItem.js";
import NavLogo from "../../assets/logo/logo_nav.webp";
import NavBarOff from "../../assets/shape/navbar_off.webp";
import NavBarOn from "../../assets/shape/navbar_on.webp";
import Whitepaper from "../Whitepaper/Whitepaper-v2.pdf";
import './NavBar.css';

export default function NavBar(props){
    const [menuIcon, setMenuIcon] = useState(NavBarOff);
    const [logoClass, setLogoClass] = useState("navbar-logo-initial0");
    const [lineClass, setLineClass] = useState("navbar-line-initial0");
    const [rectClass, setRectClass] = useState("navbar-rect-initial0");
    const [rectWrapperClass, setRectWrapperClass] = useState("navbar-rect-wrapper-initial");
    const [nameClass, setNameClass] = useState("navbar-name-initial0");
    const [isLogoActive, setIsLogoActive] = useState("");
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isBlended, setIsBlended] = useState(true);

    const formActive = useRef(false);
    const demoActive = useRef(false);
    const verseActive = useRef(false);
    const vestingActive = useRef(false);
    const isAnimationActive = useRef(true);
    const rect = useRef();

    props.biRef.navbar = {
        // playAnimation: temp,
        initLogo: initLogo,
        initLine1: initLine1,
        initLine2: initLine2,
        initLine3: initLine3,
        initLine4: initLine4,
        initLine5: initLine5,
        initLine6: initLine6,
        openDrawer: openDrawer,
        closeDrawer: closeDrawer,
        hideName1: hideName1,
        hideName2: hideName2,
        showForm1: showForm1,
        showForm2: showForm2,
        showForm3: showForm3,
        showForm4: showForm4,
        showDemo1: showDemo1,
        showDemo2: showDemo2,
        showDemo3: showDemo3,
        showDemo4: showDemo4,
        showVerse1: showVerse1,
        showVerse2: showVerse2,
        showVesting1: showVesting1,
        showVesting2: showVesting2,
        showVesting3: showVesting3,
        closeForm: closeForm,
        closeDemo: closeDemo,
        closeVerse: closeVerse,
    }

    function initLogo() {
        setLogoClass("navbar-logo-initial1");
        setTimeout(()=>{
            setIsBlended(false);
            setIsLogoActive("navbar-logo-disabled");
        }, 500);
    }

    function initLine1() {
        setLineClass("navbar-line-initial1");

        setLogoClass("navbar-logo-initial1");
        setIsBlended(false);
    }

    function initLine2() {
        setLineClass("navbar-line-initial2");

        setLogoClass("navbar-logo-initial1");
        setIsBlended(false);
    }

    function initLine3() {
        setLineClass("navbar-line-initial3");

        setLogoClass("navbar-logo-initial1");
        setIsBlended(false);
    }

    function initLine4() {
        setLineClass("navbar-line-initial4");
        setRectClass("navbar-rect-initial1");

        setLogoClass("navbar-logo-initial1");
        setIsBlended(false);
    }

    function initLine5() {
        setLineClass("");
        setRectClass("")
        setRectWrapperClass("");
        setLogoClass("");
        setNameClass("navbar-name-initial1");

        setIsBlended(false);
    }

    function initLine6() {
        setNameClass("");

        setLineClass("");
        setRectClass("")
        setRectWrapperClass("");
        setLogoClass("");
        setIsBlended(false);
        setIsLogoActive("");
        isAnimationActive.current = false;

        openDrawer();
    }

    function toggleDrawer(){
        console.log(drawerOpen);
        console.log("toggling drawer");
        if(drawerOpen === true){
            closeDrawer();
        }else{
            openDrawer();
        }
    }

    function openDrawer(){
        console.log("opening drawer");
        setDrawerOpen(true);
        setLineClass("navbar-line-open");
        setLogoClass("navbar-logo-open");
    }

    function closeDrawer(){
        console.log("closing drawer");
        setDrawerOpen(false);
        setLineClass("");
        setLogoClass("");
    }

    function hideName1(){
        setNameClass("navbar-name-end0");
    }

    function hideName2(){
        setNameClass("navbar-name-end1");
    }

    function showForm1(){
        isAnimationActive.current = true;
        setIsLogoActive("navbar-logo-disabled");
        closeDrawer();
        setNameClass("navbar-name-subpage");
        setRectClass("navbar-rect-subpage");
        setLogoClass("navbar-logo-subpage");
        setLineClass("navbar-line-form1");
    }

    function showForm2(){
        setLineClass("navbar-line-form2");
    }

    function showForm3(){
        setLineClass("navbar-line-form3");
    }

    function showForm4(){
        formActive.current=true;
        isAnimationActive.current=false;
        setIsLogoActive("");
    }

    function showDemo1(){
        isAnimationActive.current = true;
        setIsLogoActive("navbar-logo-disabled");
        closeDrawer();
        setNameClass("navbar-name-subpage");
        setRectClass("navbar-rect-subpage");
        setLogoClass("navbar-logo-subpage");
        setLineClass("navbar-line-demo1");
    }

    function showDemo2(){
        setLineClass("navbar-line-demo2");
    }


    function showDemo3(){
        setLineClass("navbar-line-demo3");
    }

    function showDemo4(){
        demoActive.current=true;
        isAnimationActive.current=false;
        setIsLogoActive("");
    }

    function showVerse1(){
        console.log("showVerse1");
        isAnimationActive.current = true;
        setIsLogoActive("navbar-logo-disabled");
        closeDrawer();
        setNameClass("navbar-name-subpage");
        setRectClass("navbar-rect-subpage");
        setLogoClass("navbar-logo-subpage");
        setLineClass("navbar-line-verse");
    }

    function showVerse2(){
        console.log("showVerse2");
        verseActive.current=true;
        isAnimationActive.current=false;
        setIsLogoActive("");
    }

    function showVesting1(){
        console.log("showVesting1");
        isAnimationActive.current = true;
        setIsLogoActive("navbar-logo-disabled");
        closeDrawer();
        setNameClass("navbar-name-subpage");
        setRectClass("navbar-rect-subpage");
        setLogoClass("navbar-logo-vesting");
        setLineClass("navbar-line-vesting1");
    }

    function showVesting2(){
        console.log("showVesting2");
        setLineClass("navbar-line-vesting2");
    }

    function showVesting3(){
        console.log("showVesting3");
        vestingActive.current=true;
        isAnimationActive.current=false;
        setIsLogoActive("");
    }

    function closeForm(){
        console.log("closing form");
        if(formActive.current==false){
            return;
        }
        console.log("actually closing form");
        props.biRef.promobanner.closeForm();
        setRectClass("");
        setLogoClass("");
        setLineClass("");
        formActive.current = false;
    }

    function closeDemo(){
        console.log("closing demo");
        if(demoActive.current==false){
            return;
        }
        console.log("actually closing demo");
        props.biRef.promobanner.closeDemo();
        setRectClass("");
        setLogoClass("");
        setLineClass("");
        demoActive.current = false;
    }

    function closeVerse(){
        console.log("closing verse");
        if(verseActive.current==false){
            return;
        }
        console.log("actually closing verse");
        props.biRef.promobanner.closeVerse();
        setRectClass("");
        setLogoClass("");
        setLineClass("");
        verseActive.current = false;
    }

    function closeVesting(){
        console.log("closing vesting");
        if(vestingActive.current==false){
            return;
        }
        console.log("actually closing vesting");
        props.biRef.promobanner.closeVesting();
        setRectClass("");
        setLogoClass("");
        setLineClass("");
        vestingActive.current = false;
    }

    function handleLogoClick(){
        if(isAnimationActive.current==true){
            return;
        }
        if(formActive.current==true){
            closeForm();
            return;
        }
        if(demoActive.current==true){
            closeDemo();
            return;
        }
        if(verseActive.current==true){
            closeVerse();
            return;
        }
        if(vestingActive.current==true){
            closeVesting();
            return;
        }
        toggleDrawer();
    }

    function handleVerseClick(e){
        console.log("verse clicked");
        e.preventDefault();
        props.biRef.promobanner.openVerse();
    }

    function handleDownloadClick(e){
        console.log("verse clicked");
        e.preventDefault();
        props.biRef.promobanner.tryItNowClicked();
    }

    function handleVestingClick(e){
        console.log("vesting clicked");
        e.preventDefault();
        props.biRef.promobanner.openVesting();
    }

    return(
        <div className={`navbar-wrapper wrtmain-section-wrapper ${isBlended ? "navbar-wrapper-blended" : ""}`}>
            <div className="navbar wrtmain-section-content">
                <div className={`navbar-drawer ${drawerOpen?"navbar-drawer-open":""}`}>
                    <NavItem text="download app"
                        type="navitem-download"
                        onClick={(e)=>handleDownloadClick(e)}/>
                    <NavItem text="future metaverse"
                        type="navitem-metaverse"
                        onClick={(e)=>handleVerseClick(e)}/>
                    <div className="navitem navitem-whitepaper">
                        <span>whitepaper</span>
                        <a href={Whitepaper} target="_blank"></a>
                    </div>
                    <NavItem text="vesting"
                        type="navitem-vesting"
                        onClick={(e)=>handleVestingClick(e)}/>
                </div>
                <img  alt="logo-workoutapp" 
                    className={`navbar-logo ${logoClass} ${isLogoActive}`}
                    src={ NavLogo }
                    onClick={()=>handleLogoClick()}/>
                <div className={`navbar-line ${lineClass}`}></div>
                <div className={`navbar-rect-wrapper ${rectWrapperClass}`}>
                    <div className={`navbar-rect ${rectClass}`}
                        onClick={()=>toggleDrawer()}></div>
                </div>
                <p className={`navbar-name ${nameClass}`}>WorkoutApp</p>
            </div>
        </div>
    )
}
