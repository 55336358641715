const IMAGES = [
    require("./dot_train_on.webp"),
    require("./dot_liquidity_on.webp"),
    require("./dot_marketing_on.webp"),
    require("./dot_metaverse_on.webp"),
    require("./dot_public_on.webp"),
    require("./dot_team_on.webp"),
    require("./dot_advisors_on.webp"),
    require("./dot_private_on.webp"),
]

export default IMAGES;
