import React from 'react';
import TEAM from "../../assets/shape/team/images.js";
import WRTMember from "../WRTMember/WRTMember";

import './Team.css';

export default function Team(props) {
    return (
        <div className="team wrtmain-section-wrapper">
            <div className="team-content wrtmain-section-content">
                <h1 className="team-header">team</h1>
                <h2 className="team-subheader">advisor</h2>
                <WRTMember
                    portrait={TEAM[0]}
                    title={"CEO & Founder"}
                    name={"Witold Witkowski, MBA"}
                    desc={"entrepreneur, strong crypto-believer,\ninvestor and academic"}
                    memberw="15.63%"
                    memberh="21.9%"
                    memberx="31.18%"
                    membery="16.85%"
                    portraitw="87.1%"
                    portraitx="2.32%"
                    portraity="11.59%"
                    gloww="100%"
                    glowh="59.56%"
                    glowx="0%"
                    glowy="0%"
                    glowr="0deg"
                    cliprx="38.54%"
                    clipry="20.56%"
                    clipx="55.08%"
                    clipy="33.14%"/>
                <WRTMember
                    portrait={TEAM[1]}
                    title={"COO & Co-Founder"}
                    name={"Łukasz Kończak"}
                    desc={"athlete, entrepreneur, crypto-enthusiast\nand trainer"}
                    memberw="15.57%"
                    memberh="22.92%"
                    memberx="54.48%"
                    membery="15.83%"
                    portraitw="133.67%"
                    portraitx="-15.61%"
                    portraity="-9.5%"
                    gloww="100%"
                    glowh="60.85%"
                    glowx="0%"
                    glowy="0%"
                    glowr="0deg"
                    cliprx="23.36%"
                    clipry="12.53%"
                    clipx="47.21%"
                    clipy="39.63%"/>
                <WRTMember
                    portrait={TEAM[2]}
                    title={"NFT concept artist"}
                    name={"Filip Drywa"}
                    desc={"graphic designer, architect, cgi expert"}
                    memberw="21.43%"
                    memberh="23.84%"
                    memberx="8.28%"
                    membery="35%"
                    portraitw="35.75%"
                    portraitx="23.1%"
                    portraity="21.82%"
                    gloww="77.19%"
                    glowh="54.05%"
                    glowx="14%"
                    glowy="10%"
                    glowr="28.19deg"
                    cliprx="68.72%"
                    clipry="37.02%"
                    clipx="60%"
                    clipy="46.13%"/>
                <WRTMember
                    portrait={TEAM[3]}
                    title={"Head of Partnerships"}
                    name={"Ola Rutkowska"}
                    desc={"experienced leader and head of sales in\nnumber of mncs"}
                    memberw="15.05%"
                    memberh="23.15%"
                    memberx="32.6%"
                    membery="35.75%"
                    portraitw="100.49%"
                    portraitx="-4.22%"
                    portraity="8.02%"
                    gloww="100%"
                    glowh="58.23%"
                    glowx="0%"
                    glowy="0%"
                    glowr="0deg"
                    cliprx="33.4%"
                    clipry="17.89%"
                    clipx="53.91%"
                    clipy="35.59%"/>
                <WRTMember
                    portrait={TEAM[4]}
                    title={"CMO"}
                    name={"Jakub Gęsiak"}
                    desc={"marketing consultant, crypto devotee,\nhead of sm"}
                    memberw="14.95%"
                    memberh="22.36%"
                    memberx="54.79%"
                    membery="36.25%"
                    portraitw="84.39%"
                    portraitx="5%"
                    portraity="14%"
                    gloww="96.9%"
                    glowh="58%"
                    glowx="1.39%"
                    glowy="0%"
                    glowr="0deg"
                    cliprx="38.54%"
                    clipry="20.69%"
                    clipx="53.08%"
                    clipy="33.64%"/>
                <WRTMember
                    portrait={TEAM[5]}
                    title={"ux designer"}
                    name={"patrycja Kulińska"}
                    desc={"Web and application designer"}
                    memberw="19.14%"
                    memberh="25.69%"
                    memberx="74.11%"
                    membery="33.15%"
                    portraitw="57.73%"
                    portraitx="19.23%"
                    portraity="33.31%"
                    gloww="100%"
                    glowh="59.74%"
                    glowx="0%"
                    glowy="3%"
                    glowr="0deg"
                    cliprx="64.15%"
                    clipry="43.67%"
                    clipx="49.78%"
                    clipy="33.01%"/>
                <WRTMember
                    portrait={TEAM[6]}
                    name={"PhD Piotr Kasprzak"}
                    desc={"consultant, financial mathematician and\ntax expert"}
                    type="wrtadvisor"
                    memberw="15.05%"
                    memberh="23.33%"
                    memberx="32.97%"
                    membery="69.68%"
                    portraitw="80.64%"
                    portraitx="6.45%"
                    portraity="14.65%"
                    gloww="100%"
                    glowh="57.77%"
                    glowx="0%"
                    glowy="0%"
                    glowr="0deg"
                    cliprx="41.63%"
                    clipry="22.32%"
                    clipx="53.95%"
                    clipy="32%"/>
                <WRTMember
                    portrait={TEAM[7]}
                    name={"Marcin Kasica"}
                    desc={"PL National Tennis Champion"}
                    type="wrtadvisor"
                    memberw="16.41%"
                    memberh="23.43%"
                    memberx="54.43%"
                    membery="69.55%"
                    portraitw="116.87%"
                    portraitx="1.15%"
                    portraity="20.31%"
                    gloww="100%"
                    glowh="62.47%"
                    glowx="0%"
                    glowy="0%"
                    glowr="0deg"
                    cliprx="28.72%"
                    clipry="15.41%"
                    clipx="42.74%"
                    clipy="19.14%"/>
            </div>
        </div>
    )
}
