import React, {useState, useEffect, useRef} from 'react';
import ReactPlayer from 'react-player/file';
import PhonesVideo from "../../assets/video/phones.mp4";
import './Phones.css';

export default function Phones(props) {
    const [textClass1, setTextClass1] = useState("phones-text-1-initial");
    const [textClass2, setTextClass2] = useState("phones-text-2-initial");
    const [textClass3, setTextClass3] = useState("phones-text-3-initial");
    const [textClass4, setTextClass4] = useState("phones-text-4-initial");
    const [wrapperClass, setWrapperClass] = useState("");

    const subtitlesTimeoutLength = 500;
    const timeoutsQueue = useRef([]);

    useEffect(() => {
      return () => {
        clearTimeouts();
      };
    },[]);

    function clearTimeouts(){
        timeoutsQueue.current.forEach((item) => {
            clearTimeout(item);
        });
        timeoutsQueue.current = [];
    }

    props.biRef.phones = {
        playAnimation: playVideo,
        playClosingAnimation: playClosingAnimation,
    }

    function playVideo(){
        console.log("playing phones video");
        let video = document.querySelector('#phones-video video');
        video.play();
        props.biRef.main.enableScrollToHowToStart();
        props.biRef.navbar.hideName1();
        setTextClass1("");
        setTextClass2("");
        setTextClass4("");

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setTextClass3("");
        },subtitlesTimeoutLength*2);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.hideName2();
        },subtitlesTimeoutLength*3);
    }

    function playClosingAnimation() {
        console.log("closing");
        setWrapperClass("phones-wrapper-gone");
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            console.log("opening");
            setWrapperClass("");
        },1000);
    }

    return(
        <div className={`phones wrtmain-section-wrapper ${wrapperClass}`}>
            <ReactPlayer
                    id="phones-video"
                    url={PhonesVideo}
                    volume={0}
                    muted={true}
                    playing={false}
                    loop={true}
                    width={"100%"}
                    height={"100%"}
                    controls={false}
                />
            <div className="phones-wrapper wrtmain-section-content">
                <p className={`phones-text-1 ${textClass1}`}
                >Simplicity is the key<br/>to success!</p>
                <p className={`phones-text-2 ${textClass2}`}
                >thanks to workoutapp you can<br/>train in the gym, ride a bike, run,<br/>swim or do yoga and at the same<br/>time earn money!</p>
                <p className={`phones-text-3 ${textClass3}`}
                >WorkoutApp</p>
                <p className={`phones-text-4 ${textClass4}`}
                >Workoutapp is the first functioning web3 application<br/>from the train2earn movement. Our ecosystem is a<br/>revolutionary development of the move2earn trend.</p>
            </div>
        </div>
    );
}
