const IMAGES = [
    require("./Witold.webp"),
    require("./Lukasz.webp"),
    require("./Filip.webp"),
    require("./Ola.webp"),
    require("./Jakub.webp"),
    require("./Patrycja3.webp"),
    require("./Piotr.webp"),
    require("./Marcin.webp"),
]

export default IMAGES;
