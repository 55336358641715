import React, {useContext, useEffect, useState, useRef} from 'react';
import { scrollIntoView } from "seamless-scroll-polyfill";
// import ReactPlayer from 'react-player/youtube';
import WorkoutApiContext from "../WorkoutApi/WorkoutApiContext";
import WRTAnimation from "../WRTAnimation/WRTAnimation";
import LedPhone from "../../assets/shape/led_phone.webp";
import LogoIntro from "../../assets/logo/logo_intro.webp";
import INTRO_IMAGES from "./INTRO_IMAGES";
import BgMeadow from "../../assets/backgrounds/meadow.webp";
import BgGradient1 from "../../assets/backgrounds/gradient_intro1.webp";
import BgGradient2 from "../../assets/backgrounds/gradient_intro2.webp";
import BgGradient3 from "../../assets/backgrounds/gradient_intro3.webp";
import SubmitIcon from "../../assets/shape/icon_submit.svg";
import DemoGlow from "../../assets/shape/demo_glow.webp";
import DemoGlow2 from "../../assets/shape/demo_glow2.webp";
import DemoGlow3 from "../../assets/shape/demo_glow3.webp";
import DemoPhone from "../../assets/shape/demo_phone.webp";
import DemoWatch from "../../assets/shape/demo_watch.webp";
import AndroidIcon from "../../assets/shape/icon_android.svg";
import AppleIcon from "../../assets/shape/icon_apple.svg";
import VerseGlow1 from "../../assets/shape/verse_glow1.webp";
import VerseGlow2 from "../../assets/shape/verse_glow2.webp";
import VerseGlow3 from "../../assets/shape/verse_glow3.webp";
import VersePoster from "../../assets/shape/verse_poster2.webp";
import VerseScroll from "../../assets/shape/verse_scroll2.webp";
import VestingGlow from "../../assets/shape/vesting_glow.webp";
import VestingGlowPanel from "../../assets/shape/vesting_glow_panel.webp";
import IconWallet from "../../assets/logo/bsc_wallet.webp";
import IconMetamask from "../../assets/logo/metamask.webp";
import emailjs from '@emailjs/browser';
import './PromoBanner.css';
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const {
    REACT_APP_EMAILJS_PUBLIC_KEY,
    REACT_APP_EMAILJS_TEMPLATE_ID,
    REACT_APP_EMAILJS_SERVICE_ID
} = process.env;


export default function PromoBanner(props) {
    const workoutApi = useContext(WorkoutApiContext);
    const [stuff, setStuff] = useState([]);

    const [image, setImage] = useState(INTRO_IMAGES[0]);
    const [bgGradient, setBgGradient] = useState(BgGradient1);
    const [animClass, setAnimClass] = useState("wrt-animation1");
    const [backgroundClass, setBackgroundClass] = useState("intro-background-initial1");
    const [textClass, setTextClass] = useState("intro-text-initial");
    const [buttonsClass, setButtonsClass] = useState("intro-buttons-initial1");
    const [buttonClass1, setButtonClass1] = useState("intro-button-initial");
    const [buttonClass2, setButtonClass2] = useState("intro-button-initial");
    const [wrapperClass, setWrapperClass] = useState("");
    const [contentClass, setContentClass] = useState("");
    const [phoneClass, setPhoneClass] = useState("");
    const [partnershipTitleClass, setPartnershipTitleClass] = useState("");
    const [partnershipSubtitleClass, setPartnershipSubtitleClass] = useState("");
    const [partnershipClass, setPartnershipClass] = useState("");
    const [blacknessClass, setBlacknessClass] = useState("");
    const [demoTitleClass, setDemoTitleClass] = useState("");
    const [demoGlowClass, setDemoGlowClass] = useState("");
    const [demoPhoneGlowClass, setDemoPhoneGlowClass] = useState("");
    const [demoGlowWrapperClass, setDemoGlowWrapperClass] = useState("");
    const [demoSubtitleClass, setDemoSubtitleClass] = useState("");
    const [demoPhoneClass, setDemoPhoneClass] = useState("");
    const [demoFrameClass, setDemoFrameClass] = useState("");
    const [demoFrameSource, setDemoFrameSource] = useState("");
    const [demoWatchClass, setDemoWatchClass] = useState("");
    const [demoButtonTopClass, setDemoButtonTopClass] = useState("");
    const [demoButtonBotClass, setDemoButtonBotClass] = useState("");
    const [demoButtonGlowTopClass, setDemoButtonGlowTopClass] = useState("");
    const [demoButtonGlowBotClass, setDemoButtonGlowBotClass] = useState("");
    const [verseGlowClass, setVerseGlowClass] = useState("");
    const [versePosterClass, setVersePosterClass] = useState("");
    const [verseVideoClass, setVerseVideoClass] = useState("");
    const [vestingGlowClass, setVestingGlowClass] = useState("");
    const [vestingTitleClass, setVestingTitleClass] = useState("");
    const [vestingPanelClass, setVestingPanelClass] = useState("");
    const [vestingTextsClass, setVestingTextsClass] = useState("");
    const [isVerseVideoPlaying, setIsVerseVideoPlaying] = useState(false);
    const animationEnded = useRef(false);
    const alreadyScrolled = useRef(false);

    const promoWrapper = useRef();
    const promoAnimation = useRef();
    const timeoutsQueue = useRef([]);
    const imageIntervalId = useRef(null);

    const imageIntervalLength = 50;
    const subpageIntervalLength = 750;
    const textIntervalLength = 500;
    const imageCount = 50;
    const imageChange1 = 25;
    const imageChange2 = 40;
    const [inputs, setInputs] = useState({});
    const frameSource = "https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FmtxaAxT9GSbg1A1LwF4K5q%2FWorkout-App---Vault-%252B-Market-%252B-Friends-list%3Fpage-id%3D0%253A1%26node-id%3D401%253A8%26viewport%3D377%252C83%252C0.13%26scaling%3Dscale-down-width%26starting-point-node-id%3D401%253A68%26disable-default-keyboard-nav%3D1%26hide-ui%3D1";

    // const claimed=.4;
    const [claimed, setClaimed] = useState(.0);

    const tokens="2,134.5 T";

    useEffect(() => {
        if (workoutApi == null) return
        getStuff();
    }, [workoutApi]);

    useEffect(() => {
      return () => {
        clearTimeouts();
        closeVerse()
      };
    },[]);

    props.biRef.promobanner = {
        // playAnimation: temp,
        playAnimation: playGlitchAnimation,
        playClosingAnimation: playClosingAnimation,
        skipToClosing: skipToClosing,
        openForm: openForm,
        closeForm: closeForm,
        closeDemo: closeDemo,
        openVerse: openVerse,
        closeVerse: closeVerse,
        tryItNowClicked: tryItNowClicked,
        openVesting: openVesting,
        closeVesting: closeVesting,
    }

    async function getStuff() {
        let stuff = await workoutApi.getStuff();
        console.log('stuff', stuff);
        setStuff(stuff);
    }

    function temp(){
        console.log("stop");
    }

    function clearTimeouts(){
        timeoutsQueue.current.forEach((item) => {
            clearTimeout(item);
        });
        clearInterval(imageIntervalId.current);
        timeoutsQueue.current = [];
        imageIntervalId.current = null;
    }

    function playGlitchAnimation(e) {
        console.log("part1 starts");
        let frame = 1;

        imageIntervalId.current = setInterval(
            function() {
                frame++;

                switch(frame){
                    case imageCount:
                        clearInterval(imageIntervalId.current);
                        playTextAnimation();
                        break;
                    case imageChange1:
                        setAnimClass("wrt-animation2");
                        setBgGradient(BgGradient2);
                        break;
                    case imageChange2:
                        setAnimClass("wrt-animation3");
                        setBgGradient(BgGradient3);
                        break;
                }
                setImage(INTRO_IMAGES[frame-1]);
            },
            imageIntervalLength
        );
        setAnimClass("wrt-animation1");
        setBackgroundClass("intro-background-initial2");
        props.biRef.navbar.initLogo();
        setTextClass("");
    }

    function playTextAnimation() {
        console.log("part2 starts");

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setButtonsClass("intro-buttons-initial2");
            props.biRef.navbar.initLine1();
        },textIntervalLength);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setButtonClass1("");
        },textIntervalLength*2);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setButtonClass2("");
        },textIntervalLength*3);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.initLine2();
            setButtonsClass("")
        },textIntervalLength*4);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setBackgroundClass("");
            props.biRef.navbar.initLine3();
            props.biRef.main.enableScroll();
            props.biRef.main.enableScrollToPhones();
        },textIntervalLength*5);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.initLine4();
        },textIntervalLength*6);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.initLine5();
        },textIntervalLength*7);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.initLine6();
            props.biRef.socialwidget.showSocialWidget();
            animationEnded.current = true;
        },textIntervalLength*8);
    }

    function playClosingAnimation() {
        alreadyScrolled.current = true;
        if(animationEnded.current == true){
            props.biRef.navbar.closeDrawer();
            setWrapperClass("promo-banner-end");
            timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
                setWrapperClass("");
            },1000);
        }
    }

    function skipToClosing() {
        // props.biRef.navbar.initLine6();
        // setTimeout(()=>{
        //     props.biRef.navbar.closeDrawer();
        //     setWrapperClass("promo-banner-end");
        //     setTimeout(()=>{
        //         setWrapperClass("");
        //     },1000);
        //     props.biRef.main.scrollToPhones();
        // }, 500);

        props.biRef.navbar.initLine6();
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.closeDrawer();
            props.biRef.navbar.hideName2();
            // props.biRef.main.scrollToHowToStart();
            props.biRef.main.enableScroll();
            props.biRef.main.enableScrollToHowToStart();
        }, 500);
    }

    function enablePhones(){
        if(alreadyScrolled.current == false){
            props.biRef.main.enableScrollToPhones();
        }
    }

    function openForm() {
        console.log("opening form");
        window.scrollTo(0,0);
        document.querySelector(".wrtmain").classList.add("body-verse-small");
        props.biRef.main.disableScrollToPhones();
        props.biRef.navbar.showForm1();
        setAnimClass("wrt-animation-subpage");
        setButtonsClass("intro-buttons-subpage");
        setTextClass("intro-text-subpage");
        setBackgroundClass("intro-background-subpage");
        setPhoneClass("phone-form1");

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.showForm2();
            setPhoneClass("phone-form2");
        },subpageIntervalLength);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setPartnershipTitleClass("partnership-title-form");
        },subpageIntervalLength*2);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.showForm3();
        },subpageIntervalLength*3);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setPartnershipSubtitleClass("partnership-subtitle-form");
        },subpageIntervalLength*4);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setPartnershipClass("partnership-show");
            props.biRef.navbar.showForm4();
        },subpageIntervalLength*5);

    }

    function closeForm() {
        console.log("closing form");
        window.scrollTo(0,0);
        document.querySelector(".wrtmain").classList.remove("body-verse-small");
        enablePhones();
        console.log("closing form classes");
        setAnimClass("wrt-animation3");
        setButtonsClass("");
        setTextClass("");
        setBackgroundClass("");
        setPhoneClass("");
        setPartnershipTitleClass("");
        setPartnershipSubtitleClass("");
        setPartnershipClass("");
        console.log("closing form classes done");
    }

    function openDemo() {
        console.log("opening demo");
        window.scrollTo(0,0);
        document.querySelector(".wrtmain").classList.add("body-verse-small");
        props.biRef.main.disableScrollToPhones();
        props.biRef.navbar.showDemo1();
        setAnimClass("wrt-animation-subpage");
        setButtonsClass("intro-buttons-subpage");
        setTextClass("intro-text-subpage");
        setBackgroundClass("intro-background-subpage");
        setPhoneClass("phone-demo1");

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.showDemo2();
            setBlacknessClass("intro-blackness-subpage");
        },subpageIntervalLength);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setPhoneClass("phone-demo2");
            setDemoTitleClass("demo-title-text-demo1");
            setDemoGlowClass("demo-glow-demo1");
        },subpageIntervalLength*2);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setDemoSubtitleClass("demo-subtitle-text-demo1");
        },subpageIntervalLength*3);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.showDemo3();
            setDemoTitleClass("demo-title-text-demo2");
            setDemoSubtitleClass("");
            setDemoGlowWrapperClass("demo-glow-wrapper-demo1");
            setPhoneClass("phone-demo1");
            setDemoPhoneClass("demo-phone-demo");
            setDemoFrameClass("demo-frame-demo");
        },subpageIntervalLength*4);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setDemoPhoneGlowClass("demo-phone-glow-demo");
        },subpageIntervalLength*5);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setDemoButtonGlowTopClass("demo-button-glow-top-demo");
            setDemoButtonTopClass("demo-button-top-demo");
        },subpageIntervalLength*6);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setDemoButtonGlowBotClass("demo-button-glow-bot-demo");
            setDemoButtonBotClass("demo-button-bot-demo");
        },subpageIntervalLength*7);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setDemoWatchClass("demo-watch-demo");
        },subpageIntervalLength*8);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.showDemo4();
            setDemoFrameSource(frameSource);
        },subpageIntervalLength*8);
    }

    function closeDemo() {
        console.log("closing demo");
        window.scrollTo(0,0);
        document.querySelector(".wrtmain").classList.remove("body-verse-small");
        enablePhones();
        setAnimClass("wrt-animation3");
        setButtonsClass("");
        setTextClass("");
        setBackgroundClass("");
        setPhoneClass("");
        setBlacknessClass("");
        setDemoTitleClass("");
        setDemoSubtitleClass("");
        setDemoGlowClass("");
        setDemoGlowWrapperClass("");
        setDemoPhoneGlowClass("");
        setDemoPhoneClass("");
        setDemoFrameClass("");
        setDemoFrameSource("");
        setDemoWatchClass("");
        setDemoButtonGlowTopClass("");
        setDemoButtonGlowBotClass("");
        setDemoButtonTopClass("");
        setDemoButtonBotClass("");
        console.log("closing demo classes done");
    }

    function openVerse() {
        console.log("opening verse");
        window.scrollTo(0,0);
        document.querySelector(".wrtmain").classList.add("body-verse");
        props.biRef.main.disableScrollToPhones();
        props.biRef.navbar.showVerse1();
        setAnimClass("wrt-animation-subpage");
        setButtonsClass("intro-buttons-subpage");
        setTextClass("intro-text-subpage");
        setBackgroundClass("intro-background-subpage");
        setPhoneClass("phone-verse1");
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setBlacknessClass("intro-blackness-subpage");
        }, subpageIntervalLength*1);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setVerseGlowClass("verse-glow-show");
        }, subpageIntervalLength*2);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setVersePosterClass("verse-poster-show");
            props.biRef.navbar.showVerse2();
        }, subpageIntervalLength*3);
    }

    function closeVerse() {
        setIsVerseVideoPlaying(false);
        window.scrollTo(0,0);
        document.querySelector(".wrtmain").classList.remove("body-verse");
        enablePhones();
        setAnimClass("wrt-animation3");
        setButtonsClass("");
        setTextClass("");
        setPhoneClass("");
        setBlacknessClass("");
        setVerseGlowClass("");
        setVersePosterClass("");
        setVerseVideoClass("");
    }


    function openVesting() {
        console.log("opening vesting");
        window.scrollTo(0,0);
        document.querySelector(".wrtmain").classList.add("body-verse-small");
        props.biRef.main.disableScrollToPhones();
        props.biRef.navbar.showVesting1();
        setAnimClass("wrt-animation-subpage");
        setButtonsClass("intro-buttons-subpage");
        setTextClass("intro-text-subpage");
        setPhoneClass("phone-vesting");
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setBlacknessClass("intro-blackness-subpage");
            setVestingGlowClass("vesting-glow-show");
        }, subpageIntervalLength*1);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setVestingTitleClass("vesting-title-show");
        }, subpageIntervalLength*2);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.navbar.showVesting2();
        }, subpageIntervalLength*3);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setVestingPanelClass("vesting-panel-show1");
        }, subpageIntervalLength*4);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setVestingPanelClass("vesting-panel-show2");
            setClaimed(.4);
        }, subpageIntervalLength*5);
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setVestingTextsClass("vesting-texts-show");
            props.biRef.navbar.showVesting3();
        }, subpageIntervalLength*6);
    }

    function closeVesting() {
        window.scrollTo(0,0);
        document.querySelector(".wrtmain").classList.remove("body-verse-small");
        enablePhones();
        setAnimClass("wrt-animation3");
        setButtonsClass("");
        setTextClass("");
        setBackgroundClass("");
        setPhoneClass("");
        setBlacknessClass("");
        setVestingGlowClass("");
        setVestingPanelClass("");
        setVestingTitleClass("");
        setVestingTextsClass("");
        setClaimed(.0);
    }

    function showVerseVideo() {
        setVerseVideoClass("verse-video-show");
    }

    function partnershipClicked() {
        console.log("partnership clicked");
        if(animationEnded.current == false){
            return;
        }
        openForm();
    }

    function tryItNowClicked() {
        console.log("tryItNow clicked");
        if(animationEnded.current == false){
            return;
        }
        openDemo();
    }

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
      if(Object.keys(inputs).length < 4){
          alert("Please fill all fields");
          return;
      }
      event.preventDefault();
      console.log(inputs);
      emailjs.send(
          REACT_APP_EMAILJS_SERVICE_ID,
          REACT_APP_EMAILJS_TEMPLATE_ID,
          inputs,
          REACT_APP_EMAILJS_PUBLIC_KEY
      ).then(function(response) {
    	   // console.log('SUCCESS!', response.status, response.text);
           alert("Form sent successfully, we will contact you soon!");
    	}, function(err) {
            // console.log('FAILED...', err);
            alert("Something went wrong, please try again");
    	});
        props.biRef.navbar.closeForm();
        setInputs({});
        document.querySelector(".partnership-form").reset();
    }

    return (
        <div className={`promo-banner wrtmain-section-wrapper ${wrapperClass}`}>
            <div className="stuffList">
                {stuff.map((s, index) => (
                    <div key={`entry-${index}`} className="stuff">
                        <div className="stuff-item">
                            {s.name}
                            {s.option}
                        </div>
                    </div>
                ))}
            </div>
            <div className={`intro-content wrtmain-section-content ${contentClass}`}>
                <WRTAnimation
                    poster={ image }
                    type={ animClass } />
                <img alt="phone"
                    className={`led-phone ${phoneClass}`}
                    src={ LedPhone }/>

                <p className="intro-load"></p>

                <div className={`intro-text ${textClass}`}>
                    <h1 className="intro-header">Train to earn</h1>
                </div>
                <div className={`intro-buttons ${buttonsClass}`}>
                    <p className="intro-subheader">
                        Discover a new way to earn crypto<br/>train<br/>getpaid
                    </p>
                    <button
                        className={`intro-button intro-button-partnership ${buttonClass1}`}
                        onClick={()=>partnershipClicked()}>
                        Partnership
                    </button>
                    <button
                        className={`intro-button intro-button-tryitnow ${buttonClass2}`}
                        onClick={()=>tryItNowClicked()}>
                        Try it now
                    </button>
                </div>

                <div className="partnership-title">
                    <p className={`partnership-title-text ${partnershipTitleClass}`}>
                        Considering partnership?
                    </p>
                </div>
                <div className="partnership-subtitle">
                    <p className={`partnership-subtitle-text ${partnershipSubtitleClass}`}>
                        Contact uS
                    </p>
                </div>
                <form
                    className="partnership-form"
                    onSubmit={handleSubmit}
                >
                    <div className={`partnership ${partnershipClass}`}>
                        <input
                            className="partnership-firstname"
                            type="text"
                            name="firstname"
                            value={inputs.firstname || ""}
                            onChange={handleChange}
                            placeholder="NAME"
                            required
                        />
                        <input
                            className="partnership-lastname"
                            type="text"
                            name="lastname"
                            value={inputs.lastname || ""}
                            onChange={handleChange}
                            placeholder="LAST NAME"
                            required
                        />
                        <input
                            className="partnership-email"
                            type="email"
                            name="email"
                            value={inputs.email || ""}
                            onChange={handleChange}
                            placeholder="EMAIL ADDRESS"
                            required
                        />
                        <div className="partnership-message-outer">
                            <PerfectScrollbar className="protected-app__scrollbar"
                                              options={{ wheelPropagation: false }}>
                                <textarea
                                    className="partnership-message"
                                    name="message"
                                    rows="4" cols="50"
                                    value={inputs.message || ""}
                                    onChange={handleChange}
                                    placeholder="YOUR MESSAGE:"
                                    required
                                />
                            </PerfectScrollbar>
                        </div>
                        <img alt="partnership" className="partnership-submit"
                            src={SubmitIcon}
                            onClick={(e)=>handleSubmit(e)}/>
                    </div>
                </form>


                <div className="demo-text-wrapper">
                    <p className={`demo-title-text ${demoTitleClass}`}>TRY IT YOURSELF</p>
                    <p className={`demo-subtitle-text ${demoSubtitleClass}`}>START EARNING</p>
                </div>
                <div className={`demo-glow-wrapper ${demoGlowWrapperClass}`}>
                    <img alt="glow"  className={`demo-glow ${demoGlowClass}`}
                        src={DemoGlow}/>
                </div>
                <img alt="phone-glow" className={`demo-phone-glow ${demoPhoneGlowClass}`}
                    src={DemoGlow2}/>
                <img alt="watch" className={`demo-watch ${demoWatchClass}`}
                    src={DemoWatch}/>
                <img alt="phone" className={`demo-phone ${demoPhoneClass}`}
                    src={DemoPhone}/>
                <iframe
                    className={`demo-frame ${demoFrameClass}`}
                    src={demoFrameSource}></iframe>
                <div className="demo-button-wrapper-top">
                    <img alt="button-glow" className={`demo-button-glow-top ${demoButtonGlowTopClass}`}
                        src={DemoGlow3}/>
                    <div className={`demo-button-top ${demoButtonTopClass}`}>
                        <p className="demo-button-text">download</p>
                        <img alt="button-icon-top" className="demo-button-icon-top"
                            src={AndroidIcon}/>
                    </div>
                </div>
                <div className="demo-button-wrapper-bot">
                    <img alt="button-glow-bot" className={`demo-button-glow-bot ${demoButtonGlowBotClass}`}
                        src={DemoGlow3}/>
                    <div className={`demo-button-bot ${demoButtonBotClass}`}>
                        <p className="demo-button-text">download</p>
                        <img alt="button-icon-bot"  className="demo-button-icon-bot"
                            src={AppleIcon}/>
                    </div>
                </div>


                <img alt="verse" className={`verse-glow1 ${verseGlowClass}`}
                    src={VerseGlow1}/>
                <img alt="verse" className={`verse-glow2 ${verseGlowClass}`}
                    src={VerseGlow2}/>
                <img alt="verse" className={`verse-poster ${versePosterClass}`}
                    src={VersePoster}
                    onClick={()=>showVerseVideo()}/>
                <div className={`verse-video ${verseVideoClass}`}>
                    {/*<ReactPlayer*/}
                    {/*    id="fm-video"*/}
                    {/*    url={"https://www.youtube.com/watch?v=NBhfZFyPEbM"}*/}
                    {/*    volume={1}*/}
                    {/*    muted={false}*/}
                    {/*    playing={isVerseVideoPlaying}*/}
                    {/*    onStart={()=>{setIsVerseVideoPlaying(true)}}*/}
                    {/*    onPlay={()=>{setIsVerseVideoPlaying(true)}}*/}
                    {/*    onPause={()=>{setIsVerseVideoPlaying(false)}}*/}
                    {/*    onEnded={()=>{setIsVerseVideoPlaying(false)}}*/}
                    {/*    loop={false}*/}
                    {/*    pip={false}*/}
                    {/*    controls={true}*/}
                    {/*    width={"100%"}*/}
                    {/*    height={"100%"}*/}
                    {/*/>*/}
                    {verseVideoClass === 'verse-video-show' ? <iframe src="https://www.youtube.com/embed/NBhfZFyPEbM?controls=0&autoplay=1"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            id="fm-video"
                            width={"100%"}
                            height={"100%"}
                    /> : null}
                </div>

                <img alt="verse" className={`verse-scroll ${verseGlowClass}`}
                    src={VerseScroll}/>
                <img alt="verse" className={`verse-glow3 ${verseGlowClass}`}
                    src={VerseGlow3}/>
                <img alt="verse" className={`verse-glow4 ${verseGlowClass}`}
                    src={VerseGlow3}/>

                <p className={`verse-text verse-text1 ${verseGlowClass}`}>
                    Along with the development of the cryptomarket and Web3, we develop.<br/>WorkoutApp will not rest only on the Application and Wallet.
                </p>
                <p className={`verse-text verse-text2 ${verseGlowClass}`}>
                     The fight for an active community will invariably be at the forefront of the values<br/>represented by the Team! One of the elements that will allow to expand the community<br/>will be the largest activity of WorkoutApp among the competition in the metaverse.
                </p>
                <p className={`verse-text verse-text3 ${verseGlowClass}`}>
                    We plan to create an exceptional WorkoutGym in metaverse<br/>nand collaborate with coaches around the world to deliver the best\ntraining experience.
                </p>
                <p className={`verse-text verse-text4 ${verseGlowClass}`}>
                    Check out the video of how our virtual world will look like and find out what you can<br/>expect from your training!
                </p>


                <img alt="verse" className={`vesting-glow ${vestingGlowClass}`}
                    src={VestingGlow}/>
                <div className="vesting-title">
                    <p className={`vesting-title-text ${vestingTitleClass}`}>
                        WORKOUT VESTING
                    </p>
                </div>
                <div className={`vesting-panel vesting-panel-total ${vestingPanelClass}`}>
                    <img alt="verse" className="vesting-panel-glow"
                        src={VestingGlowPanel}/>
                    <p className="vesting-panel-text">
                        TOKENS
                    </p>
                    <p className="vesting-panel-text vesting-panel-sum">
                        {tokens}
                    </p>
                    <img alt="verse" className="vesting-icon vesting-icon-wallet"
                        src={IconWallet}/>
                    <p className="vesting-panel-text vesting-panel-wallet">
                        BSC wallet
                    </p>
                    <img alt="verse" className="vesting-icon vesting-icon-metamask"
                        src={IconMetamask}/>
                    <p className="vesting-panel-text vesting-panel-metamask">
                        MetaMask
                    </p>
                    <p className="vesting-panel-text vesting-panel-footer">
                        Important! Beware of scammers impersonating our token
                    </p>
                </div>
                <div className={`vesting-panel vesting-panel-locked ${vestingPanelClass}`}>
                    <img alt="panel-glow" className="vesting-panel-glow"
                        src={VestingGlowPanel}/>
                    <p className="vesting-panel-text">
                        LOCKED BALANCE
                    </p>
                    <p className="vesting-panel-text vesting-panel-sum">
                        {tokens}
                    </p>
                </div>
                <div className={`vesting-panel vesting-panel-claimed ${vestingPanelClass}`}>
                    <img alt="panel-glow" className="vesting-panel-glow"
                        src={VestingGlowPanel}/>
                    <p className="vesting-panel-text">
                        CLAIMED
                    </p>
                    <p className="vesting-panel-text vesting-panel-sum">
                        {tokens}
                    </p>
                    <div className="vesting-panel-bar">
                        <div className="vesting-panel-fill" style={{width: claimed*100 + '%'}}></div>
                    </div>
                    <p className="vesting-panel-text vesting-panel-percents">{claimed*100}%</p>
                </div>
                <div className={`vesting-panel vesting-panel-ready ${vestingPanelClass}`}>
                    <img alt="glow" className="vesting-panel-glow"
                        src={VestingGlowPanel}/>
                    <p className="vesting-panel-text">
                        READY TO CLAIM ?
                    </p>
                    <div className="vesting-panel-button">
                        <p className="vesting-panel-button-text">
                            CLAIM NOW
                        </p>
                    </div>
                </div>
                <div className={`vesting-texts ${vestingTextsClass}`}>
                    <p className="vesting-text vesting-text-header">
                        SUBSCRIBERS
                    </p>
                    <p className="vesting-text vesting-text-par1">
                        You must have BNB (BEP20) in your wallet to cover network fees each time you claim your WRT token.
                    </p>
                    <p className="vesting-text vesting-text-par2">
                        All tokens will be unlocked in less than one year, it is up to you when you want to send them to the wallet
                    </p>
                    <p className="vesting-text vesting-text-par3">
                        Remember! You won’t be able to claim your tokens without clicking the “Claim WRT” button.
                    </p>
                    <p className="vesting-text vesting-text-par4">
                        WorkoutApp’s vesting schedule unlocks 2% of your tokens every week
                    </p>
                    <p className="vesting-text vesting-text-par5">
                        Once the claim is processed your tokens will be transferred to your wallet.
                    </p>
                    <p className="vesting-text vesting-text-par6">
                        Just come back once a week and collect your tokens.
                    </p>
                    <p className="vesting-text vesting-text-par7">
                        All tokens will be unlocked in less than one year, it is up to you when you want to send them to the wallet.
                    </p>
                </div>


                <div className="intro-preloader">
                    {INTRO_IMAGES.map((image, index) => (
                        <img alt="load" key={`pre-${index}`}
                            src={image}/>
                    ))}
                </div>
            </div>
            <img alt="intro-gradient" className="intro-gradient"
                src={ bgGradient }/>
            <img alt="intro-bg" className={`intro-background ${backgroundClass}`}
                src={ BgMeadow }/>
            <div className={`intro-blackness ${blacknessClass}`}></div>
        </div>
    );
}
