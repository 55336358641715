const IMAGES = [
    // require("./dumbell.png"),
    require("./stamina.webp"),
    // require("./kettlebell.png"),
    require("./glasses.webp"),
    require("./helmet.webp"),
    // require("./shoes.png"),
    require("./tennis.webp"),
    require("./watch.webp"),
    require("./buty_2.webp"),
    require("./hantle.webp"),
    require("./skakanka.webp"),
    require("./kettleball.webp"),
]

export default IMAGES;
