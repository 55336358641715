import React from 'react';
import Glow from "../../assets/shape/team_glow.webp";
import './WRTMember.css';

export default function WRTMember(props){

    const memberStyle = {
        top: props.membery,
        left: props.memberx,
        width: props.memberw,
        height: props.memberh,
    };

    const portraitStyle = {
        top: props.portraity,
        left: props.portraitx,
        width: props.portraitw,
        clipPath: `ellipse(${props.cliprx} ${props.clipry} at ${props.clipx} ${props.clipy})`,
    };

    const glowStyle = {
        top: props.glowy,
        left: props.glowx,
        width: props.gloww,
        height: props.glowh,
        transform: `rotate(${props.glowr})`,
    };

    return(
        <div
            className={`wrtmember ${props.type}`}
            style={memberStyle}>
            <img  alt="glow-team"  className="wrtmember-glow"
                style={glowStyle}
                src={Glow}/>
            <img alt={props.title} className="wrtmember-portrait"
                style={portraitStyle}
                src={props.portrait}/>
            <p className="wrtmember-title">
                {props.title}
            </p>
            <p className="wrtmember-name">
                {props.name}
            </p>
            <p className="wrtmember-description">
                {props.desc}
            </p>
        </div>
    );
}
