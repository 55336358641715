import React from 'react';
import NavItem from "../NavItem/NavItem.js";
import Neon from "../../assets/backgrounds/footer0.webp";
import Logo from "../../assets/logo/logo_footer.webp";
import Whitepaper from "../Whitepaper/Whitepaper.pdf";

import './Footer.css';

export default function Footer(props) {

    function handleRoadmapClick(e) {
        console.log("footer roadmap clicked");
        e.preventDefault();
        props.biRef.main.justScrollToRoadmap();
    }

    function handleVestingClick(e) {
        console.log("footer vesting clicked");
        e.preventDefault();
        props.biRef.promobanner.openVesting();
    }

    function handlePartnershipClick(e) {
        console.log("footer vesting clicked");
        e.preventDefault();
        props.biRef.promobanner.openForm();
    }

    function handleSocialClick(e) {
        console.log("footer social clicked");
        e.preventDefault();
        props.biRef.socialwidget.openSocialWidget();
    }

    function handleLogoClick(){
        window.scrollTo(0,0);
    }

    return (
        <div className="footer">
            <img className="footer-neon" alt="neon-1" 
                src={Neon}/>
            <img className="footer-logo" alt="footer-logo"
                onClick={()=>handleLogoClick()}
                src={Logo}/>
            <p className="footer-header">WorkoutApp</p>
            <div className="footer-left">
                <div className="navitem">
                    <span>whitepaper</span>
                    <a href={Whitepaper} target="_blank"></a>
                </div>
                <NavItem text="roadmap"
                    onClick={(e)=>handleRoadmapClick(e)}
                    />
                <NavItem text="vesting"
                    onClick={(e)=>handleVestingClick(e)}
                />
                <NavItem text="partnership"
                    onClick={(e)=>handlePartnershipClick(e)}
                />
            </div>
            <div className="footer-right">
                <NavItem text="privacy policy" link="/" />
                <NavItem text="TERMS & CONDITIONS" link="/" />
                <NavItem text="FOLLOW US ON SOCIAL MEDIA"
                    onClick={(e)=>handleSocialClick(e)}
                />
            </div>
        </div>
    )
}
