import './App.css';
import React, {useEffect, useState} from 'react';
import WorkoutApi from '../../components/WorkoutApi/WorkoutApi';
import WorkoutApiContext from "../../components/WorkoutApi/WorkoutApiContext";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import WRTMain from "../WRTMain/WRTMain";
import Temp from "../Temp/Temp";
import Mobile from './mobile';

const {
    REACT_APP_ENV
} = process.env

export default function App() {

    const [workoutApi, setWorkoutApi] = useState(null)

    function checkMobile(){
        if(window.innerWidth < 768){
            window.location = '/mobile.html';
        }
    }

    useEffect(() => {
        checkMobile();

        window.addEventListener("resize", (event) => {
            checkMobile();
        });

        async function getAccessToken() {
            let accessToken = 'aaaabbbb'
            let api = new WorkoutApi()
            api.accessToken = accessToken
            setWorkoutApi(api)
        }

        getAccessToken()
    }, []);

    return (
        <div className="app">
            <WorkoutApiContext.Provider value={workoutApi}>

                <div className="wrtmain-container">
                    <div className="content-container">
                        <Routes>
                            <Route exact path="/" element={<WRTMain/>}/>
                            <Route exact path="/temp" element={<Temp/>}/>
                            <Route exact path="*" element={<WRTMain/>}>
                            </Route>
                        </Routes>
                    </div>
                </div>
                <div className="wrtmobile text-center">
                  Loading mobile site ...
                </div>
            </WorkoutApiContext.Provider>
        </div>
    );
}
