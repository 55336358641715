import React from 'react';
import './WRTAnimation.css';

export default function WRTButton(props){

    return(
        <img
            className={"wrt-animation " + props.type}
            src={props.poster}
            alt={props.alt}
        />
    );
}
