import React, {useState, useEffect, useRef} from 'react';
import Glow from "../../assets/shape/nft_glow.webp";
import ArrowLeftEmpty from "../../assets/shape/arrow_left.webp";
import ArrowLeftFull from "../../assets/shape/arrow_left_full.webp";
import ArrowRightEmpty from "../../assets/shape/arrow_right.webp";
import ArrowRightFull from "../../assets/shape/arrow_right_full.webp";
import PortalTop from "../../assets/shape/portal_top.webp";
import PortalTopGlow from "../../assets/shape/portal_top_glow.webp";
import PortalBot from "../../assets/shape/portal_bot.webp";
import PortalBotGlow from "../../assets/shape/portal_bot_glow.webp";
import TOKENS from "../../assets/shape/nfts/images.js";

import './NFT.css';


export default function NFT(props) {
    const [nftLeftImage, setNftLeftImage] = useState(TOKENS[1]);
    const [nftMidImage, setNftMidImage] = useState(TOKENS[2]);
    const [nftRightImage, setNftRightImage] = useState(TOKENS[3]);
    const [headerClass, setHeaderClass] = useState("nft-header-initial");
    const [glowClass, setGlowClass] = useState("nft-glow-initial");
    const [glowClass2, setGlowClass2] = useState("nft-glow2-initial");
    const [glowClass3, setGlowClass3] = useState("nft-glow3-initial");
    const [arrowClass, setArrowClass] = useState("nft-arrow-initial");
    const [portalLeftClass, setPortalLeftClass] = useState("nft-portal-left-initial");
    const [portalMidClass, setPortalMidClass] = useState("nft-portal-mid-initial");
    const [portalRightClass, setPortalRightClass] = useState("nft-portal-right-initial");
    const [portalFakeClass, setPortalFakeClass] = useState("nft-portal-fake-initial");
    const [portalDownClass, setPortalDownClass] = useState("nft-portal-down-initial");
    const [leftArrow, setLeftArrow] = useState(ArrowLeftEmpty);
    const [rightArrow, setRightArrow] = useState(ArrowRightEmpty);
    const [textClass, setTextClass] = useState("nft-text1-initial");
    const [textClass2, setTextClass2] = useState("nft-text3-initial");
    const leftIndex = useRef(1);
    const isAfterPortalSwap = useRef(false);

    const disabledArrows = useRef(false);
    const animationIntervalLength = 700;
    const timeoutsQueue = useRef([]);

    useEffect(() => {
      return () => {
        clearTimeouts();
      };
    },[]);

    function clearTimeouts(){
        timeoutsQueue.current.forEach((item) => {
            clearTimeout(item);
        });
        timeoutsQueue.current = [];
    }

    props.biRef.nft = {
        playAnimation: playAnimation,
        playAnimation2: playAnimation2,
        playAnimation3: playAnimation3,
    }

    function playAnimation(){
        console.log("NFT1 starts");
        props.biRef.main.enableScrollToNFT2();
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            setHeaderClass("");
            setGlowClass("");
            setPortalLeftClass("");
            setPortalMidClass("");
            setPortalRightClass("");
            setArrowClass("");
        }, 500);
    }

    function playAnimation2() {
        console.log("NFT2 starts");

        props.biRef.main.enableScrollToNFT3();
        isAfterPortalSwap.current = true;
        setPortalRightClass("nft-portal-fake");

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            // setPortalRightClass("nft-portal-fake");
            setPortalFakeClass("");
            setTextClass("");
            setGlowClass2("");
        }, 700);

        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            props.biRef.main.scrollToNFT3();
        }, 3000)
    }

    function playAnimation3() {
        console.log("NFT3 starts");

        props.biRef.main.enableScrollToTokenomics1();
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(()=>{
            // setPortalRightClass("");
            setTextClass2("");
            setPortalDownClass("");
            setGlowClass3("");
        }, 500);
    }

    function rotateLeft() {
        console.log("rotating left");
        leftIndex.current = leftIndex.current == 0 ? TOKENS.length-1 : leftIndex.current - 1;
        console.log(leftIndex.current);
        let tempImageLeft = TOKENS[leftIndex.current];
        let tempImageMid = leftIndex.current == TOKENS.length - 1 ? TOKENS[0] : TOKENS[leftIndex.current + 1];
        let tempImageRight = leftIndex.current == TOKENS.length - 2 ? TOKENS[0] : TOKENS[leftIndex.current + 2];
        tempImageRight = leftIndex.current == TOKENS.length - 1 ? TOKENS[1] : tempImageRight;
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function() {
                setNftLeftImage(tempImageLeft);
                setNftMidImage(tempImageMid);
                setNftRightImage(tempImageRight);
                setPortalLeftClass("");
                setPortalMidClass("");
                if(isAfterPortalSwap.current == true){
                    setPortalFakeClass("");
                    setPortalRightClass("nft-portal-fake");
                }else{
                    setPortalRightClass("");
                }
            }, animationIntervalLength
        );
        setPortalLeftClass("nft-portal-changing");
        setPortalMidClass("nft-portal-changing");
        if(isAfterPortalSwap.current == true){
            setPortalFakeClass("nft-portal-changing");
            setPortalRightClass("nft-portal-fake nft-portal-changing");
        }else{
            setPortalRightClass("nft-portal-changing");
        }
    }

    function rotateRight() {
        console.log("rotating right");
        leftIndex.current = leftIndex.current == TOKENS.length-1 ? 0 : leftIndex.current + 1;
        console.log(leftIndex.current);
        let tempImageLeft = TOKENS[leftIndex.current];
        let tempImageMid = leftIndex.current == TOKENS.length - 1 ? TOKENS[0] : TOKENS[leftIndex.current + 1];
        let tempImageRight = leftIndex.current == TOKENS.length - 2 ? TOKENS[0] : TOKENS[leftIndex.current + 2];
        tempImageRight = leftIndex.current == TOKENS.length - 1 ? TOKENS[1] : tempImageRight;
        timeoutsQueue.current[timeoutsQueue.current.length] = setTimeout(
            function() {
                setNftLeftImage(tempImageLeft);
                setNftMidImage(tempImageMid);
                setNftRightImage(tempImageRight);
                setPortalLeftClass("");
                setPortalMidClass("");
                if(isAfterPortalSwap.current == true){
                    setPortalFakeClass("");
                    setPortalRightClass("nft-portal-fake");
                }else{
                    setPortalRightClass("");
                }
            }, animationIntervalLength
        );
        setPortalLeftClass("nft-portal-changing");
        setPortalMidClass("nft-portal-changing");
        if(isAfterPortalSwap.current == true){
            setPortalFakeClass("nft-portal-changing");
            setPortalRightClass("nft-portal-fake nft-portal-changing");
        }else{
            setPortalRightClass("nft-portal-changing");
        }
    }

    function fillLeftArrow(){
        setLeftArrow(ArrowLeftFull);
    }

    function emptyLeftArrow(){
        setLeftArrow(ArrowLeftEmpty);
    }

    function fillRightArrow(){
        setRightArrow(ArrowRightFull);
    }

    function emptyRightArrow(){
        setRightArrow(ArrowRightEmpty);
    }

    return(
        <div className="nft wrtmain-section-wrapper">
            <div className="nft-content wrtmain-section-content">

                <div className="nft-part nft-part1"></div>

                <img  alt="nft-1"  className={`nft-glow ${glowClass}`}
                    src={ Glow }/>
                <h1 className={`nft-header ${headerClass}`}>NFTs</h1>
                <h1 className={`nft-header nft-header-front ${headerClass}`}>NFTs</h1>
                <img  alt="nft-arrow-left"  className={`nft-arrow-left ${arrowClass}`}
                    src={leftArrow}
                    onMouseOver={()=>fillLeftArrow()}
                    onMouseOut={()=>emptyLeftArrow()}
                    onClick={()=>rotateLeft()}/>
                <img  alt="nft-arrow-right"  className={`nft-arrow-right ${arrowClass}`}
                    src={rightArrow}
                    onMouseOver={()=>fillRightArrow()}
                    onMouseOut={()=>emptyRightArrow()}
                    onClick={()=>rotateRight()}/>

                <div className={`nft-portal nft-portal-left ${portalLeftClass}`}>
                    <img  alt="nft-item"  className="nft-item"
                        src={nftLeftImage}/>
                    <img  alt="nft-stand"  className="nft-stand"
                        src={PortalBot}/>
                </div>

                <div className={`nft-portal nft-portal-mid ${portalMidClass}`}>
                    <img  alt="nft"  className="nft-item"
                        src={nftMidImage}/>
                    <img alt="nft" className="nft-stand-bot"
                        src={PortalBot}/>
                    <img alt="nft" className="nft-stand-glow"
                        src={PortalBotGlow}/>
                    <img alt="nft" className="nft-stand-top"
                        src={PortalTop}/>
                    <img alt="nft" className="nft-stand-top-glow"
                        src={PortalTopGlow}/>
                </div>

                <div className={`nft-portal nft-portal-right ${portalRightClass}`}>
                    <img alt="nft" className="nft-item"
                        src={nftRightImage}/>
                    <img alt="nft" className="nft-stand"
                        src={PortalBot}/>
                </div>


                <div className={`nft-portal nft-portal-right ${portalFakeClass}`}>
                    <img alt="nft" className="nft-item"
                        src={nftRightImage}/>
                    <img alt="nft" className="nft-stand"
                        src={PortalBot}/>
                </div>

                <div className="nft-part nft-part2"></div>

                    <img alt="nft-glow2" className={`nft-glow2 ${glowClass2}`}
                        src={Glow}/>
                    <p className={`nft-text1 ${textClass}`}>
                        The NFTs serve<br/>as your ticket to<br/>real earnings<br/>from training!
                    </p>
                    <p className="nft-text2">
                        The workoutapp team has created 5 nft levels for 10 sports. In addition, 2 legendary levels will be<br/>released. These will significantly increase your“efficiency” and earning rate. Legendary nfts will be<br/>super rare and  extremely desirable.
                    </p>

                <div className="nft-part nft-part3"></div>

                    <img alt="nft-glow3" className={`nft-glow3 ${glowClass3}`}
                        src={Glow}/>

                    <p className={`nft-text3 ${textClass2}`}>
                        Users who have NFT in their<br/>treasury can lend them to other<br/>trainees and then earn half of<br/>the profit remaining after<br/>deducting the cost of the<br/>Stamina needed to perform the<br/>next training. One NFT can be<br/>lent once at the time.
                    </p>

                    <div className={`nft-portal nft-portal-down ${portalDownClass}`}>
                        <img alt="nft" className="nft-item"
                            src={TOKENS[0]}/>
                        <img alt="nft" className="nft-stand"
                            src={PortalBot}/>
                        <img alt="nft" className="nft-stand-glow"
                            src={PortalBotGlow}/>
                    </div>
            </div>
        </div>
    )
}
